<template>
    <div>{{ information }}</div>
</template>

<script>

import { onMounted, onUnmounted } from 'vue';

export default {

    name: 'dummy-component',
    
    components: {  },

    setup() {

      onMounted(() => {
      });

      onUnmounted(() => {
      });

      return {}
    },

  data() {
    return {
      information: '',
    };
  },
};
</script>

