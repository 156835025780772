<template>
    <!--<div v-if="current_data != undefined">-->
    <div v-if="current_data.booking.kind != undefined">
      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
            <div style="padding: 4px; padding-left: 7px; font-size: 20px; padding-bottom: 10px;">
              Action Menu
              <button
                type="button"
                class="btn-close"
                @click="$emit('close')"
                aria-label="Close modal"
              >
              &nbsp;&nbsp;&nbsp;x
              </button>
            </div>
            </header>

            <section class="modal-body" id="modalDescription">

              <div class="grid-container">
                <div class="main_left">
                  <booking-summary
                    :current_data="current_data.booking"
                  >
                  </booking-summary>
                </div>
                <div class="main_right">
                  <payment-history
                    edit_mode="no"
                    :current_data="current_data"
                  >
                  </payment-history>
                </div>
              </div>

              <hr>

              <div class='margin-bottom2'>
                <base-button @click="menuSelected('slip', current_data.booking.dataid)" mode="grey medium">
                  print slip
                </base-button>
              </div>

              <div class='margin-bottom2'>
                <base-button @click="menuSelected('memo', current_data.booking.dataid)" mode="grey medium">
                  view memo
                </base-button>
              </div>

              <div class='margin-bottom2'
                v-if="current_data.booking.kind!=='C' && current_data.booking.dataid.length==10"
              >
                <base-button @click="menuSelected('modify')" mode="grey medium">
                  modify direct booking
                </base-button>
              </div>

              <div class='margin-bottom2'
                v-if="current_data.booking.kind!=='C' && current_data.booking.dataid.length==10"
              >
                <base-button @click="menuSelected('cancel')" mode="grey medium">
                  cancel direct booking
                </base-button>
              </div>

            </section>

            <footer class="modal-footer">
              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>

    <booking-form2 v-if="open_booking_form"
      current_application="searchbooking"
      :current_mode="current_mode"
      :current_data="current_data.booking"
      @close="open_booking_form=false; $emit('close')"
    >
    </booking-form2>

</template>

<script>
import {ActionMenuUtils} from  '@/classes/Utils/ActionMenuUtils.js';

import { ref, onMounted, computed } from 'vue';
import { searchbooking } from '@/classes/SearchBooking.js';

export default {
  name: 'BookingForm',
  components: { },
  
  setup() {

    const current_mode = ref('');

    const open_booking_form = ref(false);

    const current_data = computed(() =>
      searchbooking.get_current_data()
    );

    // save local currentData
    onMounted(() => {
    });

    function menuSelected(menu, dataid) {
      switch (menu) {
        case 'slip': ActionMenuUtils.printSlip(dataid); break;
        case 'memo': ActionMenuUtils.viewMemo(dataid); break;
        case 'modify': openBookingForm('modify'); break;
        case 'cancel': openBookingForm('cancel'); break;
      }
    }

    function openBookingForm(mode) {
      current_mode.value = mode;
      open_booking_form.value = true;
    }
    
    function refetch() {
      searchbooking.refetch();
    }

    return { menuSelected, open_booking_form, current_data, current_mode,
            refetch, };

  },

}
</script>

<style scoped>
  .modal {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 650px;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 1.5s;
    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    border-radius: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    justify-content: center;
    /*background-color: green;*/
    background-color: white;
    display: flex;
    border-radius: 5px;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: black;
    font-weight: bold;
    justify-content: space-between;
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    border-radius: 5px;
  }

  .modal-body {
    position: relative;
    padding: 5px 10px;
    border-radius: 5px;
    justify-content: left;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.margin-bottom2 {
  margin-bottom: 2px;
}
.grid-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 0px;
  background-color: #001a33;
  padding: 0px;
}
.grid-container > div {
  background-color: #001a33;
  text-align: left;
  padding: 10px;
  font-size: 18px;
  color: white;
}
.main_left {  grid-column: 1 / span 1; }
.main_right {  grid-column: 2 / span 1; }


</style>