<template>
<!--        <h2 v-if="select=='regular'"
            style="padding:0px; margin: 0px;">Regular Bookings
        </h2>
        <h2 v-else
            style="padding:0px; margin: 0px;">Canceled Bookings
        </h2>
-->
        <show-payentries
          :payentries="payentries"
        >
        </show-payentries>
        
        <show-payentries-stats
          :stats="stats"
        >
        </show-payentries-stats>
</template>
<script>
import ShowPayentries from './ShowPayentries.vue';
import ShowPayentriesStats from './ShowPayentriesStats.vue';
import {FormatUtils} from  '@/classes/Utils/FormatUtils.js';
import {DateUtils} from  '@/classes/Utils/DateUtils.js';
import { accounting } from '@/classes/Accounting.js';
import { computed } from 'vue';
export default {

    props: [ 'select', ],    // data received from the parent component (myaccounting)
    name: 'DayAccount',
    components: { ShowPayentries, ShowPayentriesStats, },

    setup(props) {

      const payentries = computed(() => { 

        switch (props.select) {
          case 'regular': 
            return accounting.get_day_normal_payentries();
          case 'cancel': 
            return accounting.get_day_cancel_payentries();
        }
        return false;
      } );

      const stats = computed(() => { 

        switch (props.select) {
          case 'regular': 
            return accounting.get_stats_normal_payentries();
          case 'cancel': 
            return accounting.get_stats_cancel_payentries();
        }

        return false;   // dummy return
      } );

      return { FormatUtils, DateUtils, payentries, stats}

    },
}
</script>


<style scoped>
  .pink {
    background-color: rgb(209, 195, 189);
  }
  .green {
    background-color: rgb(157, 212, 210);
  }
.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}

     
      .black {
        color: black;
      }

</style>

