<template>

  <!--
    booking-form will be closed by clicking "close" button
  -->
  <booking-form2 v-if="open_booking_form"
      current_application="addbooking"
      current_mode="add"
      current_data="dummy"
      @close="open_booking_form=false">
  </booking-form2>

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
    name: 'AddBooking',
    components: {  },

    setup() {

      const open_booking_form = ref(false);

      onMounted(() => {
        open_booking_form.value = true;
      });

      onUnmounted(() => {
        open_booking_form.value = false;
      });

      return { open_booking_form, }
    }
};
</script>

