import { ref, } from 'vue';
import { PmsAccess, } from '@/classes/DbAccess/PmsAccess.js';

// global
class Information extends PmsAccess {
  constructor(pms_response) {
    super(pms_response);
    this.myInfo = ref([]);
    this.current_information = ref('');
    this.current_index = ref(-1);
    this.current_data = ref({});
  }

  get_information() { return this.myInfo.value; }
  get_current_information() { return this.current_information.value; }
  get_current_index() { return this.current_index.value; }
  get_current_data() { return this.current_data.value; }

  set_current_index(index) { this.current_index.value = index; }
  set_current_data(data) { this.current_data.value = data; }

  async add_info(data) {    // data={info:'site' or 'phone',name:'xx',memo:'xx'}
    await this.update_pms('Informations', "add", data);
  }
  //ok
  async delete_info(what, id) {    // data={info:'site' or 'phone',id:'xx'}
    let data = {info:what, id};
    await this.update_pms('Informations', "delete", data);
  }
  //ok
  async update_info(data) {    // data={info:'site' or 'phone',id:'xx',name:'',memo:'xx'}
    await this.update_pms('Informations', "update", data);
  }
  //ok
  async fetch_info(data) {    // data='site' or 'phone' (STRING)
    this.current_information.value = data;
    this.current_index.value = -1;
    await this.fetch_pms('Informations', "get", data);
    if (this.pms_response.type == 'success')
        this.myInfo.value = this.pms_response.response;
  }
}
export let information = new Information();
