<template>
  <br>
  
  <div class="inside-container">
    
    <div class="inside-div-label">checkin
    </div>
    <div class="inside-div-input">
      <datepicker-lite id="checkin"
        :value-attr="checkin"
        :locale="pickerConfig.locale"
        @value-changed="changeEvent1"/>
    </div>
    
    <div class="inside-div-label"> checkout
    </div>
    <div class="inside-div-input">
      <datepicker-lite id="checkout"
        :value-attr="checkout"
        :locale="pickerConfig.locale"
        @value-changed="changeEvent2"/>
    </div>
                
    <div class="inside-div-label">room type
    </div>
    <div class="inside-div-input">
      <select id="roomtype" v-model="roomtype">
        <option disabled value="">Select Room Type</option>
        <option value='D2New'>{{ roomDescription('D2New') }}</option>
        <option value='P2'>{{ roomDescription('P2') }}</option>
        <option value='P3'>{{ roomDescription('P3') }}</option>
        <option value='ANN'>{{ roomDescription('ANN') }}</option>
        <option value='N302B'>{{ roomDescription('N302B') }}</option>
        <option value='A2'>{{ roomDescription('A2') }}</option>
        <option value='A3'>{{ roomDescription('A3') }}</option>
        <option value='A4'>{{ roomDescription('A4') }}</option>
        <option value='A5-1'>{{ roomDescription('A5-1') }}</option>
        <option value='A6-A'>{{ roomDescription('A6-A') }}</option>
        <option value='A6'>{{ roomDescription('A6') }}</option>
      </select>                    
    </div>

    <div class="inside-div-label">n guests
    </div>
    <div class="inside-div-input">
      <input type="number" v-model="nguests" placeholder="Enter no of guests">
    </div>
    
    <div class="inside-div-label">discount rate(%)
    </div>
    <div class="inside-div-input">
        <input type="number" v-model="discount" placeholder="Enter discount rate">
    </div>
      &nbsp;
    <base-button @click="myformaction()">Get Quotation</base-button>
  </div>
                  
</template>

<script>
import DatepickerLite from 'vue3-datepicker-lite';
import {DateUtils} from '@/classes/Utils/DateUtils.js'
import { roomDescription } from '@/classes/Quotation.js'

import { ref,  } from 'vue'
export default {
  name: 'getrequirement',
  components: {DatepickerLite},

  setup(_, { emit }) {

      const checkin = ref('')
      const checkout = ref('')
      const roomtype = ref('')
      const nguests = ref('')
      const discount = ref('')
      const pickerConfig = ref ( {
        locale: {
          format: "YYYY/MM/DD",
          weekday: ["sun", "mon", "tue", "wed", "thr", "fri", "sat"],
          startsWeeks: 0,
          todayBtn: "TODAY",
          clearBtn: "Clear",
          closeBtn: "Close"
        }
      })
        
      function changeEvent1 (value)  {
        checkin.value = value;
      }

      function changeEvent2 (value) {
        checkout.value = value;
      }

      function myformaction() {
        if (!verifyInput()) return;
        var quote_condition = { 
                  checkin: checkin.value,
                  checkout: checkout.value,
                  roomtype: roomtype.value,
                  nguests: nguests.value,
                  discount: discount.value,
                  nights: DateUtils.diffBetweenPickerDays(checkin.value, checkout.value)
        };
        emit('quote-condition', quote_condition)
      }

      function verifyInput() {
        // checkin and checkout are in datepicker format (2021/01/02)
        //alert('toyy='+toYyyyMmDd(checkin.value))
        //alert('today='+offsetedDay(0))
        
        if (DateUtils.toYyyyMmDd(checkin.value)<DateUtils.offsetedDay(0, 'yyyymmdd')) {alert('checkin should be today or later. try again'); return false;}
        if (DateUtils.toYyyyMmDd(checkout.value)<=DateUtils.toYyyyMmDd(checkin.value)) { alert('checkout is not valid. try again'); return false;}
        //if (dates.compare(a,b))
        switch (roomtype.value) {
          // case 'N302B' added 2021/11/08
          case 'D2New':
          case 'P2':  case 'P3':  case 'ANN': case 'N302B':
          case 'A2':    case 'A3':  case 'A4':  case 'A5-1':
          case 'A6-A':  case 'A6':
            return true;

          default: alert('please select a room.'); return false;
        }
      }


      return { checkin, checkout, roomtype, nguests, discount, myformaction,
                roomDescription, pickerConfig, changeEvent1, changeEvent2 }

  },

};
</script>


<style scoped>

.inside-container {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
  margin: 0px 0px 0px 0px;
}
.inside-div-label {
  background-color: white;
  text-align: left;
  padding: 0; margin: 5px 1px 0px 1px;
  font-size: 15px;
  font-weight: bold;
}
.inside-div-input {
  background-color: white;
  text-align: left;
  padding: 0; margin: -1px 1px 0px 1px;
  font-size: 20px;
}

/*  line 1  */
.line_item {
  grid-column: 1 / span 1;
}

input {
  width: 100%;
}
select {
  width: 100%;
}

input[type=text], select {
  width: 100%;
  padding: 2px 2px;
  margin: 2px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=text] {
  background-color: white;
  color: black;
}
input[type=number] {
  background-color: white;
  color: black;
}
input[type=text]:focus {
  background-color: lightblue;
}
input[type=number]:focus {
  background-color: lightblue;
}
select {
  width: 100%;
  padding: 2px 2px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}
</style>
