import { ref, } from 'vue';
import { BookingForm } from '@/classes/BookingForm.js';
class Accounting extends BookingForm {
  constructor(pms_response) {
    super(pms_response);
    
    // day_booking = [ {booking: {}, payentry: [{}], roomassign: }, ....]
    this.day_normal_booking = ref([]);
    this.day_cancel_booking = ref([]);
    
    this.stats_normal_day_booking = ref({});
    this.stats_cancel_day_booking = ref({});

    // day_payentries = [ {}, {}, .....]
    this.day_normal_payentries = ref([]);
    this.day_cancel_payentries = ref([]);
    
    this.stats_normal_payentries = ref({});
    this.stats_cancel_payentries = ref({});

    // small_ccounting = { small_entries: [{}, {}, ..], money_counting: { }}
    this.small_entries = ref([]);
    this.money_counting = ref({});

    this.stats_small_entries = ref({});

    this.current_data = ref({});
    
    this.target_date = ref("");
    
    this.account_date = ref("");
    
    this.mydate = '';
   
  }  
  
  get_account_date()        { return this.account_date.value; }
  set_account_date(mydate)        { this.account_date.value = mydate; }

  get_day_normal_booking()        { return this.day_normal_booking.value; }
  get_day_cancel_booking()        { return this.day_cancel_booking.value; }
  get_stats_normal_day_booking()  { return this.stats_normal_day_booking.value; }
  get_stats_cancel_day_booking()  { return this.stats_cancel_day_booking.value; }

  get_day_normal_payentries()     { return this.day_normal_payentries.value; }
  get_day_cancel_payentries()     { return this.day_cancel_payentries.value; }
  get_stats_normal_payentries()   { return this.stats_normal_payentries.value; }
  get_stats_cancel_payentries()   { return this.stats_cancel_payentries.value; }

  get_small_entries()             { return this.small_entries.value; }
  get_money_counting ()           { return this.money_counting.value; }
  
  get_stats_small_entries()       { return this.stats_small_entries.value; }

  set_current_data(payload) {
    this.current_data.value = payload;
  }  
  
  get_current_data() {
    return this.current_data.value;
  }  
  
  async add_payentry(data) {    // data={dataid, targetdate   (STRING)
    await this.update_pms('Payentries', "add", data);
  }
  async delete_payentry(data) {    // data=id   (STRING)
    await this.update_pms('Payentries', "delete", data);
  }
  
  async add_small_entry(data) {    // data={kind, what, method, income, outcome, memo, staff}
    await this.update_pms('SmallEntries', "add", data);
  }
  
  async update_money_counting(data) {    // data={tenthoudsnds:1, .....}
    await this.update_pms('MoneyCountings', "update", data);
  }
  
  async modify_checkin_status(data) { // body={dataid, newstatus:'yes'}
    await this.update_pms('Bookings', "update_checkin_status", data);
  }
 
  
  fetch_data(mydate) {
      this.mydate = mydate;
      this.refetch();
  }
  
  async refetch() {
    await this.fetch_daybooking(this.mydate);
    await this.fetch_payentry(this.mydate);
    await this.fetch_small_entry(this.mydate);
    await this.fetch_money_counting(this.mydate);
  }

  async fetch_daybooking(data) {    // data=targetdate   (STRING)
    await this.fetch_pms('Bookings', "daybooking", data);
    if (this.pms_response.type == 'success') {
      this.day_normal_booking.value = this.pms_response.response.normal;
      this.day_cancel_booking.value = this.pms_response.response.cancel;
      this.stats_normal_day_booking.value = this.get_stats_on_booking(this.day_normal_booking.value);
      this.stats_cancel_day_booking.value = this.get_stats_on_booking(this.day_cancel_booking.value);
    }
  }
  
  async fetch_payentry(data) {    // data=targetdate   (STRING)
    await this.fetch_pms('Payentries', "get", data);
    if (this.pms_response.type == 'success') {
      this.day_normal_payentries.value = this.pms_response.response.normal;
      this.day_cancel_payentries.value = this.pms_response.response.cancel;
      this.stats_normal_payentries.value = this.get_stats_on_payentries(this.day_normal_payentries.value);
      this.stats_cancel_payentries.value = this.get_stats_on_payentries(this.day_cancel_payentries.value);
    }
  }

/*  async print_daily_accounting() {
    await this.fetch_pms('PayEntries', "print_daily_accounting", this.mydate);
  }
*/
  //ok
  async fetch_small_entry(data) {    // data=targetdate   (STRING)
    await this.fetch_pms('SmallEntries', "get", data);
    if (this.pms_response.type == 'success') {
      this.small_entries.value = this.pms_response.response;
      this.stats_small_entries.value = this.get_stats_on_small_entries(this.small_entries.value);
    }
  }
  
  async fetch_money_counting(data) {    // data=targetdate  (STRING)
    await this.fetch_pms('MoneyCountings', "get", data);
    if (this.pms_response.type == 'success') {
      this.money_counting.value = this.pms_response.response;
    }
  }
  
  get_stats_on_booking(bookings) {
    let loop_size = bookings.length;
    let loop_size_payentry = 0;
    let total_guest = Number(0);
    let total_payment = Number(0);
    let total_balance = Number(0);
    let total_price = Number(0);
    for (let i=0; i<loop_size; i++) {
      total_guest += bookings[i].booking.nguests;
      total_price += bookings[i].booking.price;
      for (let j=0; j<loop_size_payentry; j++) {
        total_payment += bookings[i].payentry[j].amount
      }
    }
    total_balance = total_price - total_payment;
    return {total_guest: total_guest, total_price: total_price, 
      total_payment: total_payment, total_balance: total_balance};
  }

  
  get_stats_on_payentries(payentries) {
    let loop_size = payentries.length;
    let total = Number(0);
    let prepay = Number(0);
    let point = Number(0);
    let cash = Number(0);
    let card = Number(0);
    let gmo = Number(0);
    let meta = Number(0);
    let bank = Number(0);
    for (let i=0; i<loop_size; i++) {
      switch(payentries[i].minor) {
        case 'prepay': prepay += payentries[i].amount; break;
        case 'point': point += payentries[i].amount; break;
        case 'cash': cash += payentries[i].amount; break;
        case 'card': card += payentries[i].amount; break;
        case 'gmo': gmo += payentries[i].amount; break;
        case 'meta': meta += payentries[i].amount; break;
        case 'bank': bank += payentries[i].amount; break;
      }
    }
    total = prepay + point + cash + card + gmo + meta + bank;
    return {prepay, point, cash, card, gmo, meta, bank, total}
  }


  get_stats_on_small_entries(entries) {
    let loop_size = entries.length;
    let total = Number(0);
    let cashin = Number(0);
    let cashout = Number(0);
    let card = Number(0);
    let gmo = Number(0);
    let meta = Number(0);
    let bank = Number(0);
    for (let i=0; i<loop_size; i++) {
      switch(entries[i].method) {
        case 'cash': 
          cashin += entries[i].income; 
          cashout +=entries[i].outcome; 
          break;
        case 'card': card += entries[i].income - entries[i].outcome; break;
        case 'gmo': gmo += entries[i].income - entries[i].outcome; break;
        case 'meta': meta += entries[i].income - entries[i].outcome; break;
        case 'bank': bank += entries[i].income - entries[i].outcome; break;
      }
    }

    total = cashin - cashout + card + gmo + meta + bank;
    return {cashin, cashout, card, gmo, meta, bank, total}
  }

  getO_A_N(agent, outdate, nights) {
    return outdate.substr(6, 2) + "-" + agent + " (" + nights + ")";
  }

  mypaystatus(price, payentry) {
    var mybalance;
    mybalance = price - this.sumOfPaymentIncludingLoss(payentry);
    if (mybalance == 0) return 'yes';
    else if (mybalance > 0) return 'no';
    else return 'over';
  }

  sumOfPaymentIncludingLoss(mypayentries) {
    var mytotal = Number(0);
    mypayentries.forEach((value) => {
      mytotal += Number(value.amount);
    });
    return mytotal;
  }

  isprepaid(myway, payentry) {
    var loopsize = payentry.length;
    if (myway == 'Yes') return 'pyes';
    else {
      for (var i = 0; i < loopsize; i++) {
        if (payentry[i].minor == 'prepay') return 'pyes';
      }
    }
    return 'pno';
  }

  add_2_price(prepay, nonrefundable, breakfast, prepayable) {
    var mybreak = breakfast;
    if (breakfast == '朝食' || breakfast == '朝食2') mybreak = '朝食';
    // adding 14 days in advance prepayable
    if ((prepay != 'pyes') && (nonrefundable != 'Yes') && (mybreak != '朝食') && (prepayable != 'FOURTEEN') && (prepayable != 'THREE')) return "";
    var build = "";
    if (prepay == 'pyes') build += "💰";
    if (mybreak == '朝食') build += "🍴";
    if (nonrefundable == 'Yes') { build += "👎"; return build; }      //   �🚫😍💰🅿🙅🈚🈲👎❌🔟 
    if (prepayable == 'SEVEN') build += "7️⃣";
    if (prepayable == 'FOURTEEN') build += "🔟";
    if (prepayable == 'THREE') build += "3️⃣";
    return build;
  }

  sumOfPayments(payentry) {
    let total = Number(0);
    payentry.forEach(function (value) {
      total = total + Number(value.amount);
    })

    return total;
  }
}
export let accounting = new Accounting();
