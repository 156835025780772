<template>
              <div class="grid-container">
                <div class="field1">
                  <span 
                      :class="{ bordered: myTitleClass=='site' }"
                      @click="selectinfo('site')">
                      Site Info
                  </span>
                </div>
                <div class="field2">
                  <span 
                      :class="{ bordered: myTitleClass=='phone' }"
                      @click="selectinfo('phone')">
                      Phone Book
                  </span>
                </div>
              </div>


</template>

<script>
import { ref, } from 'vue';
import { information } from  '@/classes/Informations.js';

export default {
  components: {  },

  setup() {
      const myTitleClass = ref('');

      function selectinfo(what) {
        myTitleClass.value = what;
        information.fetch_info(what);
      }

      return { selectinfo, myTitleClass, }
  },

};
</script>

<style scoped>

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0px;
  background-color: white;
  padding: 1px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 5px 0;
  font-size: 18px;
}

.field1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.field2 {
  grid-column-start: 2;
  grid-column-end: 3;
}


.bordered  {
  border: 1px solid black;
  background-color: black;
  color:white;
  margin: 2px;
}



</style>  
