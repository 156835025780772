export class Validator {
  
  /*
    spec = { requred: yes, type: , }
              type: "string", minlength:3, maxlength:20
              type: "number" meet: "nozero_minus" / "nozero_plus" / "zero_plus" / "zero_minus"onzero nonnegative" / "nonnegative"
              type: "one_in_array", array: []
  
  */
  static validate(title, value, spec) {
    switch (spec.type) {
      case "string":
        if (value.length<spec.minlength) { alert(title + ' must be ' + spec.minlength + ' chars or longer'); return false;}
        if (value.length>spec.maxlength) { alert(title + ' must be ' + spec.maxlength + ' chars or less'); return false;}
        return true;
        
      case "number":
        switch (spec.meet) {
          case "nozero_minus": 
          if (value>=0) { alert(title + ' must be non-zero and must be minus'); return false;}
          break;
        
          case "nozero_plus":
            if (value<=0) { alert(title + ' must be non-zero and must be plus'); return false;}
            break;
            
          case "zero_plus":
            if (value<0) { alert(title + ' must be zero or plus'); return false;}
            break;
            
          case "zero_minus":
            if (value>0) { alert(title + ' must be 0 or minus'); return false;}
            break;
        }
        return true;
        
      case "one_in_array":
        if (spec.array.indexOf(value.trim()) === -1) { alert('invalid entry in ' + title); return false; }
        return true;
    }
  }
}
