<template>
  <div class="grid-container2">
    <div class="left">
      {{ username }}
    </div>
    <div class="right">
      Sophiearth PMS
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { userinfo } from '@/classes/UserInfo.js';

export default {

  setup() {

    const username = computed(() => userinfo.get_username() );

    return { username,  };

  }
}
</script>

<style scoped>
.grid-container2 {
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-areas:
    'left right';
  grid-gap: 0px;
  background-color: #640032;
  padding: 0px;
}

/* Style the left column */
.left {
  grid-area: left;
  font-size: 22px;
  text-align: left;
  color: white;
  background-color: #640032;
  padding: 5px;
}

/* Style the right column */
.right {
  grid-area: right;
  font-size: 22px;
  text-align: center;
  color: white;
  font-weight: bold;
  background-color: #640032;
  padding: 5px;
}
</style>