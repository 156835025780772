<template>
<container>

        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>

        <table v-else width="100%">
          <tr>
            <td width="10%" style="vertical-align: top">

              <table width="100%">

                <tr>
                  <td><div @click="leftDateChange(-7)"><img src="double_red_left.gif"></div></td>
                  <td><div @click="leftDateChange(-1)"><img src="red_left_arrow.gif"></div></td>
                  <td><div @click="leftDateChange(1)"><img src="green_right_arrow.gif"></div></td>
                  <td><div @click="leftDateChange(7)"><img src="double_green_right.gif"></div></td>
                </tr>

                <tr>
                  <td colspan="4">
                        <div class="DeleteRegion"
                          @drop="onDropToDelete($event)"
                          @dragover.prevent
                          @dragenter.prevent
                          >
                              <span>Drag Here to Delete</span>
                        </div>
                  </td>
                </tr>

                <tr
                    :style="{backgroundColor: leftDateStyle.back, color: leftDateStyle.color}"
                >

                  <th class="border" colspan="4">
                    {{  displayEnglishDate(leftDate)  }}
                  </th>
                </tr>

              </table>

              <table class='borderedtable' style="vertical-align: top">

                <tr v-for="(booking, index) in onedayBookings" :key="index">

                    <td nowrap class='border' v-bind:class = "[booking.completed]">

                      <div
                        @dragstart="startDragFromLeft($event, booking.dataid, booking.indate, booking.dayIndexToAssign, booking.nights, booking.completed, booking.seq)"
                        draggable="true">
                        <span>
                          {{ booking.guestname }}
                        </span>
                      </div>

                    </td>

                </tr>

              </table>

            </td>

            <td width="90%">

              <table width="60%" style="margin-left: auto; margin-right: auto;">

                <tr>

                  <td><div @click="rightDateChange(-7)"><img src="double_red_left.gif"></div></td>
                  <td><div @click="rightDateChange(-1)"><img src="red_left_arrow.gif"></div></td>
                  <td><div @click="rightDateChange(1)"><img src="green_right_arrow.gif"></div></td>
                  <td><div @click="rightDateChange(7)"><img src="double_green_right.gif"></div></td>
                  <td></td>
                  <td></td>

                </tr>

              </table>
<div class="wrapper">
<div class="tablebox">
              <table class="borderedtable">
<colgroup>
<col width="5%">
<col width="13%">
<col width="13%">
<col width="13%">
<col width="13%">
<col width="13%">
<col width="13%">
<col width="13%">
</colgroup>
<thead>
<tr>
<th class="sticky"></th>
<th class="sticky" :style="{backgroundColor: rightDateStyleArray[index].back, color: rightDateStyleArray[index].color}"
v-for="(mydate, index) in rightDateArray" :key="index">
{{ displayEnglishDate(mydate) }}
</th>
</tr>
</thead>
<tbody>
                
		<tr v-for="(roomcell, myrow) in roomdatecells" :key="myrow">
                  <td class="border" v-bind:class="[roomcell[0].type]">
                      {{ roomcell[0].name }}                             
                  </td>
                  <td nowrap class="border" v-for="(roomday, mycol) in roomcell" :key="mycol"
                        v-bind:class=decide_colors(roomday)
                        v-bind:id="roomday.dataid">
                        <div
                          @drop="onDrop($event, myrow, mycol)"
                          @dragover.prevent
                          @dragenter.prevent
                          >
                            <div
                              @dragstart="startDragFromRight($event, roomday.dataid, roomday.indate, roomday.dayIndexToAssign, roomday.nights, roomday.completed, roomday.seq, myrow, mycol)" 
                              draggable="true" >
                              <span v-bind:class="[roomday.hidden]">
                                {{ roomday.guestname }}
                              </span>
                            </div>
                        </div>
                  </td>
                </tr>
</tbody>
              </table>
</div>
</div>
            </td>

          </tr>
          
        </table>
</container>
</template>

<script>
  import { DateUtils } from  '@/classes/Utils/DateUtils.js';
  import { roomassign } from '@/classes/RoomAssign.js';

  import { ref, onMounted, computed } from 'vue';

	export default {
    
    props: [],

		components: { },

    setup() {

      const isLoading = ref(false);

      const rightDateArraySize = ref(7);

      const today = DateUtils.offsetedDay(0, 'yyyymmdd');
      
      const leftDateOffset = ref(0);
      const rightDateOffset = ref(0);
      const leftDate = ref('');
      const rightDateArray = ref([]);

      const onedayBookings = computed(() => roomassign.get_onedayBookings() );
      const roomnames = computed(() => roomassign.get_roomnames() );
      const roomdatecells = computed(() => roomassign.get_roomdatecells() );
	
	// 2022 10 01  displaying non-zero balanced booking in red
	function decide_colors(roomday) {
		if (roomday.completed=='C1') return roomday.completed;
		else if (roomday.balance>0) return 'unpaid';
		else return roomday.background;


/*if (roomday.balance>0) {
console.log('balance>0');
return 'unpaid';
}*/
//		return roomday.background;
	}

      // left date style and right date style
      const leftDateStyle = computed(() => {
        if ( today.value == leftDate.value) return {back: 'black', color: 'yellow'};
        return {back: 'purple', color: 'white'};
      });

      const rightDateStyleArray = computed(() => {
        var myarray = [];
        for (var i=0; i<rightDateArraySize.value; i++) {
          if ( today.value == rightDateArray.value[i])
            myarray.push( {back: 'black', color: 'yellow'} );
          else 
            myarray.push( {back: 'purple', color: 'white'} );
        }
        return myarray;
      });

      function update_left_date() {
        leftDate.value = DateUtils.offsetedDay(leftDateOffset.value, 'yyyymmdd');
      }
      function update_right_date_array() {
        let i = 0;
        rightDateArray.value = [];
        for (i=0; i<rightDateArraySize.value; i++) {
          rightDateArray.value.push( DateUtils.offsetedDay(rightDateOffset.value+i, 'yyyymmdd') );
        }
      }
      onMounted(() => { 
        update_left_date();
        update_right_date_array();
        fetch_room_assign() 
      });

      async function fetch_room_assign() {
        await roomassign.fetch_roomassigns(leftDate.value, rightDateArray.value);
      }

      // this is called from the handler....
      //   when room assign is changed
      async function update_and_refech(payload) {
        isLoading.value = true;
        await roomassign.update_room_assign(payload);
        await fetch_room_assign();
        isLoading.value = false;
      }
      
      async function delete_and_refetch(dataid) {
        isLoading.value = true;
        await roomassign.delete_room_assign(dataid);
        await fetch_room_assign();
        isLoading.value = false;
      }

      function leftDateChange( shift ) {
        leftDateOffset.value += shift;
        update_left_date();
        fetch_room_assign();
      }

      function rightDateChange( shift ) {
        rightDateOffset.value += shift;
        update_right_date_array();        
        fetch_room_assign();
      }

      function displayEnglishDate(mydate) {
        let month = DateUtils.monthName(mydate, 'en');
        let day = DateUtils.dayName(mydate, 'en');
        let dayofweek = DateUtils.dayOfWeek(mydate, 'en');
        return '' + month + ' ' + day + ' (' + dayofweek + ')';
      }

      // event handler for dragging from the table on the left
      function startDragFromLeft (evt, dataid, indate, dayIndexToAssign, nights, completed, seq) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        const mydata = {source: 'left', dataid: dataid, indate: indate, dayIndexToAssign: dayIndexToAssign, nights: nights, completed: completed, seq: seq};
        evt.dataTransfer.setData('transferedData', JSON.stringify(mydata))
        //evt.dataTransfer.setData('transferedData', {source: 'left', dataid: dataid, seq: seq})
        //console.log('transferedData in dragging from left = source: left, dataid: ' + dataid + ', seq=' + seq )
      }

      // event handler for dragging from the table on the right
      function startDragFromRight (evt, dataid, indate, dayIndexToAssign, nights, completed, seq, sourcerow, sourcecol) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        const mydata = {source: 'right', dataid: dataid, indate: indate, dayIndexToAssign: dayIndexToAssign, nights: nights, completed: completed, seq: seq, sourcerow: sourcerow, sourcecol: sourcecol};
        evt.dataTransfer.setData('transferedData', JSON.stringify(mydata))
        //evt.dataTransfer.setData('transferedData', dataid)
        //evt.dataTransfer.setData('transferedData', {source: 'right', dataid: dataid, seq: seq})
        //console.log('transferedData in dragging from right = source: right, dataid: ' + dataid + ', seq=' + seq )
      }
      
      // event handler for dropping in the table on the right
      function onDrop (evt, targetrow, targetcol) {
        const mydata = evt.dataTransfer.getData('transferedData')
        const obj = JSON.parse(mydata)

        switch (obj.source) {
          case 'left': handleDragFromLeft(obj.dataid, obj.indate, obj.dayIndexToAssign, obj.nights, obj.completed, obj.seq, targetrow, targetcol); break;
          case 'right': handleDragFromRight(obj.dataid, obj.indate, obj.dayIndexToAssign, obj.nights, obj.completed, obj.seq, obj.sourcerow, obj.sourcecol, targetrow, targetcol); break;
          default: alert('error in assign-room::: should be dragging from left or right')
        }
      }

      // event handler for dropping to the "Delete Room Assign" area
      function onDropToDelete (evt) {
        const mydata = evt.dataTransfer.getData('transferedData')
        const obj = JSON.parse(mydata)
        
        if (obj.completed=='C0') {
          alert('nothing to delete. try again');
          return;
        }

        if (confirm('are you sure about deleting room assignment?')) {
          delete_and_refetch(obj.dataid);
        }
        else return;
      }

      // {"dataid":"200002931424","guestname":"guest (P2:1/31)","seq":0,
      //  "room":"B/B/B/B/B/B/B/B/B/B/B/B","class":"p","completed":"C2",
      //  "roomrowcol":[{"row":15,"col":0},{"row":15,"col":1},.......],
      //  "indate":"20201228","outdate":"20210128","nights":"31","agent":"drct2","myagent":"dum"}
      // this is to assign rooms by dragging a booking from the left table
      function handleDragFromLeft(dataid, indate, dayIndexToAssign, nights, completed, seq, targetrow, targetcol) {

        // 0. if completed='C2', 
        if (completed=='C2') {
          alert('room assignment was completed. can not do again'); return;
        }
        // 1. check rightdatearray[targetcol] != checkin
        if (rightDateArray.value[targetcol] != indate) {
          alert('you are assigning on different date. try again'); return;
        }

        // 2. check if [targetrow, targetcol] is not empty
        if (roomdatecells.value[targetrow][targetcol].guestname != "12ss") { 
          alert('the target celll is not empty. try again'); return;
        }

        // 3. check if other future cells on the same row is occupied for the booking
        var days2assign = dayIndexToAssign; var i;
        var ncount = 0;
        for (i=targetcol; i<7; i++) {   // 0, 1, 2, 3, 4, 5, 6
          if (roomdatecells.value[targetrow][i].guestname != "12ss") { alert('some target cells are not empty. try again'); return; }
          else ncount++;
          if (days2assign >= (nights-1)) break;
          if (i<6) {days2assign++; }
        }

        completed = 'C1';
        if ( days2assign==(nights-1) ) completed = 'C2';

        // 4. update DB
        const tempobj = {
          name: roomnames.value[targetrow],
          dayIndexToAssign: dayIndexToAssign,
          counting: ncount,
          dataid: dataid,
          seq: seq,
          completed: completed
        }
        update_and_refech(tempobj);
      }

      // this is to assign rooms by dragging a booking from the right table
      // either copying rooms (completing room assignment) or changing rooms
      function handleDragFromRight(dataid, indate, dayIndexToAssign, nights, completed, seq, sourcerow, sourcecol, targetrow, targetcol) {

        //console.log('handleDragFromRight::dataid='+dataid)

        //const myrowinfo = this.roomdatecells[targetrow];
        
        // 1. (dragging into the same cell)
        if ( (sourcerow == targetrow) && (sourcecol == targetcol) ) {
          alert('you are dragging to the same cell. try again'); return;
        }

        // 2. (dragging into the past date)
        if (targetcol < sourcecol) {
          alert('you are dragging to the past cell. try again'); return;
        }

        // 3. check targetcol == sourcecol (moving a cell)
        if (targetcol == sourcecol) {
          //alert('we are moving to other room in the same day')
          if (roomdatecells.value[targetrow][targetcol].guestname != "12ss") { 
            alert('the target celll is not empty. try again'); return;
          }

          // now calculate dayIndexToassign for the index to update the roomassign for
          //indate = 20210101      targetdate = 20210102 이면 dayIndexToAssign = rightday-indate = 1
          dayIndexToAssign = DateUtils.diffBetweenDays(indate, rightDateArray.value[targetcol]);
          const tempobj = {
            name: roomnames.value[targetrow],
            dayIndexToAssign: dayIndexToAssign,
            counting: 1,
            dataid: dataid,
            seq: seq,
            completed: completed
          }
          update_and_refech(tempobj);
          return;
        }

        // 4. check targetcol > (sourcecol+1) source=2 target = 2(no), 3(ok),  4, 5, 6 (NO)
        //console.log('source='+sourcecol)
        //console.log('target='+targetcol)
        if (targetcol > (sourcecol+1)) {
          alert('to copy rooms you have to drag to the cell right next from the original cell. try again'); return;
        }

        // 여기까지 오면 다음의 사항은 다 점검되어 해결되었다.
        //   예외사항: 1. 동일 셀로 drop, 2. 과거 셀로 drop. 3. 현재+2셀로 이동
        //   처리사항: 동일 열에서 방 이동... (허용)
        //
        // 5. now copy cells    roomrowcol

        // 5.1. if completed='C2', 
        if (completed=='C2') {
          alert('room assignment was completed. can not copy rooms'); return;
        }

        var days2assign = dayIndexToAssign; var i;
        var ncount = 0;
        for (i=targetcol; i<7; i++) {   // 0, 1, 2, 3, 4, 5, 6
          if (roomdatecells.value[targetrow][i].guestname != "12ss") { alert('some target cells are not empty. try again'); return; }
          else ncount++;
          if (days2assign >= (nights-1)) break;
          if (i<6) {days2assign++; }
        }

        if ( days2assign==(nights-1) ) completed = 'C2';

        // 4. update DB   1, 2, 3, 4, 5, 6, 7, 8
        const tempobj = {
          name: roomnames.value[targetrow],
          dayIndexToAssign: dayIndexToAssign,
          counting: ncount,
          dataid: dataid,
          seq: seq,
          completed: completed
        }
        update_and_refech(tempobj);
      }

      return {
        //isModalRoomChangeVisible, isModalTaxiAlarmVisible,

        leftDateChange, rightDateChange, 

        onDrop, onDropToDelete, startDragFromLeft, startDragFromRight,

        //printCleanSheet, printBreakfast,

        roomnames, roomdatecells,

	decide_colors,

        onedayBookings, 

        leftDateOffset, rightDateOffset,

        leftDate, rightDateArray, 

        rightDateStyleArray, leftDateStyle,

        displayEnglishDate,

      };


    },

};
</script>

<style scoped>

.wrapper {width:100%; height: 100vh;}
.wrapper .tablebox {position: relative; height: 900px; overflow-y: scroll;}
.borderedtable thead tr th {position: sticky; top:0}
div.ex1 {
width: 100%;
height: 1000px;
overflow: scroll;
}

div.sticky {
position: sticky;
top:0;
}


div.fixed {
position: fixed;
bottom: 0;
right: 0;
width: 100%;
}

.DeleteRegion {
  box-sizing: content-box;  
  padding: 2px 10px 2px 10px;
  margin: 3px 2px 3px 2px;
  /*padding: 1px;  */
  border: 4px solid red;
  background-color: red;
  color: white;
  border-radius: 20px;
}

/*  room assignment status */
.C0 {
  color: white;
  background-color: red;
}
.C2 {
  color: black;
}
.C1 {
  background-color:blue;
  color: red;
  animation:blinkingText 1.2s infinite;
}

.redblink {
background-color:blue;
color: white;
animation:blinkingText 1.2s infinite;

}
.unpaid {
background-color: red;
color: white;
}


.blinking{
    animation:blinkingText 1.2s infinite;
}
@keyframes blinkingText{
    0%{     color: red;    }
    49%{    color: red; }
    60%{    color: transparent; }
    99%{    color:transparent;  }
    100%{   color: red;    }
}

.hidden {
  /*display: block;*/
  color: white;
  /*visibility: hidden;*/
}
.display {
  /*display: block;*/
  /*color: black;*/
  visibility: visible;
}

.park {
  background-color: lightgrey;
}
.buffer {
  background-color: white;
}
.dorm {
  background-color: darkgrey;
}
.floatleft {
  float: left;
}

.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}


.peachpuff {
  background-color: peachpuff;
}
.gold {
  background-color: gold;
}
.yellow {
  background-color: yellow;
}
.wheat {
  background-color: wheat;
}
.violet {
  background-color: violet;
}
.yellowgreen {
  background-color: yellowgreen;
}
.springgreen {
  background-color: springgreen;
}
.thistle {
  background-color: thistle;
}
.turquoise {
  background-color: turquoise;
}
.silver {
  background-color: silver;
}
.palegreen {
  background-color: palegreen;
}
.orange {
  background-color: orange;
}
.tomato {
  background-color: tomato;
}
.lightpink {
  background-color: lightpink;
}
.steelblue {
  background-color: rgb(177, 135, 245);
}
.pink {
  background-color: pink;
}
.olive {
  background-color: rgb(207, 152, 178);
}
.tan {
  background-color: tan;
}
.peru {
  background-color: peru;
}
.salmon {
  background-color: salmon;
}
.plum {
  background-color: plum;
}
.mediumorchid {
  background-color: mediumorchid;
}
.lightskyblue {
  background-color: lightskyblue;
}
.khaki {
  background-color: khaki;
}
.indianred {
  background-color: rgb(164, 169, 241);
}
</style>

