import { ref, } from 'vue';
import { PmsAccess, } from '@/classes/DbAccess/PmsAccess.js';

    
// global
export function roomDescription(roomtype) {

        const roomNames = [ 'D2New', 'P2',  'P3',   'ANN', 'N302B', 'A2',   'A3',     'A4',    'A5-1',  'A6-A',   'A6' ]
        const description = [
                    "D2New: 1 double bed, private shower:toilet",
                    "P2: 2 persons (bunk bed), private bath:toilet",
                    "P3: 3 persons (1 double, 1 single), private bath:toilet",
                    "ann: 3 persons (1 double, 1 single), shared bath:toilet",
                    "N302B: 3 persons (1 double, 1 single), shared bath:toilet",
                    "A2: Apartment (2 single)",
                    "A3: Apartment (2 single, 1 underbed)",
                    "A4: Apartment (4 single)",
                    "A5-1: Apartment (2 double, 1 sofa)",
                    "A6-A: 2 room apartment (2 double, 2 underbed)",
                    "A6:  2 room apartment (2 double, 2 underbed)"]

        return description[roomNames.indexOf(roomtype)]

    }

    export function roomCapacity(roomtype) {

        const roomNames = [ 'D2New', 'P2',  'P3',   'ANN',  'N302B', 'A2',   'A3',     'A4',    'A5-1',  'A6-A',   'A6' ]
        const description = [
                    "D2New: Max Persons is 2. More people will be surcharged.",
                    "P2: Max Persons is 2. More people will be surcharged.",
                    "P3: Max Persons is 3. More people will be surcharged.",
                    "ann: Max Persons is 3. More people will be surcharged.",
                    "N302B: Max Persons is 3. More people will be surcharged.",
                    "A2: Max Persons is 2. More people will be surcharged.",
                    "A3: Max Persons is 3. More people will be surcharged.",
                    "A4: Max Persons is 4. More people will be surcharged.",
                    "A5-1: Max Persons is 5. More people will be surcharged.",
                    "A6-A: Max Persons is 6. More people will be surcharged.",
                    "A6: Max Persons is 6. More people will be surcharged."]

        return description[roomNames.indexOf(roomtype)]

    }

    // rate = price rate from the basic price 예를 들어 baseprice=10, rate=1.1 then price=10*1.1
    export function roomPrice(myrate, roomtype, disRate) {

        const rateName  = ['m50', 'm40', 'm30', 'm20', 'm10', 'regular', 'p10', 'p20', 'p30', 'p40', 'p50', 'p60', 'p70', 'p80', 'p90', 'p100', 'p110', 'p120', 'p130', 'p140', 'p150', 'p160', 'p170', 'p180', 'p190', 'p200', 'p300', 'p500', 'p600', 'p1000']
        const rate      = [0.5,   0.6,    0.7,   0.8,   0.9,    1.,      1.1,   1.2,   1.3,   1.4,   1.5,  1.6,    1.7,   1.8,   1.9,   2.,     2.1,     2.2,    2.3,    2.4,    2.5,   2.6,     2.7,    2.8,   2.9,     3.,      4.,   6.,      7.,    11.]


const roomNames = [ 'D2New', 'P2',  'P3',   'ANN',  'N302B', 'A2',   'A3',     'A4',    'A5-1',  'A6-A',   'A6' ]
        const basePrice = [ 5000.0, 5000.0, 7000.0, 7500.0, 7500.0, 8000.0, 11000.0,  14000.0, 14000.0, 15000.0,  18000.0]
    
        return rate[rateName.indexOf(myrate)] * basePrice[roomNames.indexOf(roomtype)] * disRate
    }

class Quotation extends PmsAccess {
  constructor(pms_response) {
    super(pms_response);
    this.result = ref([]);
  }

  get_quotation() { return this.result.value; }

  async fetch_quotation(roomtype, checkin, nights) {
    let data = {roomtype, checkin, nights};
    await this.fetch_pms('Quotations', 'get', data);
    if (this.pms_response.type == 'success') {
       this.result.value = this.pms_response.response;
       console.log('result')
       console.log(this.result.value)
    }
  }
}

export let quotation = new Quotation()
