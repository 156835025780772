<template>
<div class="grid-container"  style="background-color:#aaa;">

  <div class="left" style="background-color:#aaa;">
        <div class="BoxRegion">
          <h1 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">Sophiearth Quotation</h1>
          
          <div v-if="dataQuotationAvailable">

            <button type="button" class="btn btn-danger" @click="printquotation">PRINT</button>
            <div class="grid-container2">

              <DisplayQuotation 
                :myQuotation="quotationResult" 
                :myOtherInfo="otherInfo">
              </DisplayQuotation>
              
            </div>

          </div>
        </div>
  </div>

  <div class="right" style="background-color:#aaa;">
        <div class="BoxRegion">
          <h1 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">Quote Condition</h1>
          <div class="grid-container2">
                <get-requirement @quote-condition="new_quote_handler"></get-requirement>
          </div>
        </div>
  </div>
</div>

</template>

<script>
  import GetRequirement from "./GetRequirement.vue";
  import DisplayQuotation from "./DisplayQuotation.vue";

  //import QuotationUtils from '@/composables/QuotationUtils.js'
  import {DateUtils} from '@/classes/Utils/DateUtils.js'
  import {FormatUtils} from '@/classes/Utils/FormatUtils.js'
  import { quotation, roomPrice, roomDescription, roomCapacity } from '@/classes/Quotation.js'
  import { ref, computed } from 'vue';

  export default {
    components: { GetRequirement, DisplayQuotation },
    props: [],
    setup() {

      //const { roomPrice, roomDescription, roomCapacity } = QuotationUtils();

      const roomtype = computed( () => quotation.get_quotation().roomtype  );
      const rank = computed( () => quotation.get_quotation().rank  );
      const date = computed( () => quotation.get_quotation().date  );
      const nguests = ref('0');
      const discount = ref(0);
      const isLoading = ref(false);
      const quote_condition = ref({});

      const dataQuotationAvailable = computed( () => 
          !isLoading.value && quotation.get_quotation().date!= undefined && quotation.get_quotation().date.length>=1 );

      const quotationResult = computed( () => calDailyPrice(roomtype.value, nguests.value, rank.value, date.value, discount.value) );

      const otherInfo = computed( () => {
        let otherInfo = {
            roomDescription: roomDescription(roomtype.value),
            roomcapacity: roomCapacity(roomtype.value),
            petParkingExtra: 'pet and parking cost extra',
            validity: 'This quotation is only valid for 3 days from the Quote Day',
            today: DateUtils.display_date_for_accounting(DateUtils.offsetedDay(0, 'yyyymmdd'), 'en')
        }
        return otherInfo;
      });

      async function new_quote_handler(quote_spec) {
        
        let roomtype = quote_spec.roomtype;
        let checkin = quote_spec.checkin;
        let nights = quote_spec.nights;
	discount.value = quote_spec.discount
        
        /* quote_condition 
            { roomtype, indate, outdate, noguests, discount }
        */
        
        // save condition for local use
        quote_condition.value = quote_spec;
        
        // check validity of user input
        //if (!quotation.verify_condition(quote_condition.value)) return;
        
        // for proper input, request quote
        await quotation.fetch_quotation(roomtype, checkin, nights);
      }

      function printquotation() {
        var myArg1 = JSON.stringify(quotationResult.value);
        var myArg2 = JSON.stringify(otherInfo.value);
        window.open("./api/PrintQuote.php?arg1="+myArg1+'&arg2='+myArg2, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
      }

      function calDailyPrice(roomtype, nguests, ranknames, dates, discount) {

        var myoutarray = [], price = 0, total = 0, disTotal=0;

        var mysize = ranknames.length
        for (var i=0; i<mysize; i++) {
          price = roomPrice(ranknames[i], roomtype, 1)
          myoutarray.push( { date: DateUtils.display_date_for_accounting(DateUtils.toYyyyMmDd(dates[i]), 'en'), dayOfWeek: DateUtils.dayOfWeek(DateUtils.toYyyyMmDd(dates[i]), 'en'), dateprice: FormatUtils.display_money(price), rank: ranknames[i] } )
          total += price
        }
        disTotal = total * Number ( 1 - discount/100 )
        return { roomtype: roomtype, nguests: nguests, dailyprice: myoutarray, total: FormatUtils.display_money(total), disTotal: FormatUtils.display_money(disTotal), discount: discount }
      }

      return { new_quote_handler, quotationResult, printquotation, otherInfo, dataQuotationAvailable }

    },
};
</script>

<style scoped>

.BoxRegion {
  box-sizing: content-box;  
  padding: 2px 10px 10px 10px;
  margin: 3px 2px 3px 2px;

  /*padding: 1px;  */
  border: 1px solid red;
  background-color: white;
  color: black;
  border-radius: 20px;
}


/* The grid container */
.grid-container {
  display: grid;
  grid-template-areas: 
    'left right';
  /* grid-column-gap: 10px; - if you want gap between the columns */
} 

.left,
.right {
  padding: 3px;
  height: auto; /* Should be removed. Only for demonstration */
}

/* Style the left column */
.left {
  grid-area: left;
}

/* Style the right column */
.right {
  grid-area: right;
}

.grid-container2 {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
}

.grid-container2 > div {
  background-color: /*rgba(255, 255, 255, 0.8)*/ white;
  text-align: center;
  padding: 0px 0;
  font-size: 22px;
}

</style>

