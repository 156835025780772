<template>
<div class="grid-container"  style="background-color:#aaa;">
  
  <div class="header">
    <select-info/>
  </div>


  <div class="left" style="background-color:#aaa;">
        <div class="BoxRegion">
          <h2 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">
            {{  leftTitle  }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <base-button 
                v-if="current_information=='site' || current_information=='phone'"
                @click="new_info_handler" 
                mode="purple medium">
                ADD
            </base-button>
          </h2>
          <!-- left side  ::    booking list -->

          <div class="grid-container2">
              <list-info
                v-if="dataInfoAvailable"
                @item-clicked="open_detail_view=true"
              ></list-info>
          </div>
        </div>
  </div>

  <div class="right" style="background-color:#aaa;">
        <div class="BoxRegion">
          <div class="grid-container2">
              <view-detail
                v-if="open_detail_view && current_index!=-1"
              ></view-detail>
          </div>
        </div>
  </div>
</div>

  <info-form
    v-if="open_info_form"
    mode="add"
    current_data="dummy"
    @close="open_info_form=false;"
  >
  </info-form>
  
</template>

<script>
  import SelectInfo from './SelectInfo.vue';

  import ListInfo from './ListInfo.vue';
  import ViewDetail from './ViewDetail.vue';

  import { ref, computed } from 'vue';
  import { information } from '@/classes/Informations.js';

	export default {
    components: { SelectInfo, ListInfo, ViewDetail, },
    props: [],

    setup() {

      const open_detail_view = ref(false);
      const open_info_form = ref(false);

      const current_index = computed(() => information.get_current_index() );
      const current_information = computed(() => information.get_current_information() );
      const dataInfoAvailable = computed(() => information.get_information().length>=1 );

      const leftTitle = computed(() => {
        switch (current_information.value) {
          case 'site': return 'Site';
          case 'phone': return 'Phone Book';
        }
        return null;
      });

      // add button is clicked, open form for adding an entry
      function new_info_handler() {
        open_info_form.value = true;
      }

      return {  current_index, current_information,

                open_detail_view, open_info_form,

                new_info_handler, 
                
                dataInfoAvailable, leftTitle,
      };
    },
};
</script>

<style scoped>

.BoxRegion {
  box-sizing: content-box;  
  padding: 2px 10px 10px 10px;
  margin: 3px 2px 3px 2px;

  /*padding: 1px;  */
  border: 1px solid red;
  background-color: white;
  color: black;
  border-radius: 20px;
}

.header {
  grid-area: header;
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  font-size: 22px;
}

/* The grid container */
.grid-container {
  display: grid;
  grid-template-areas: 
    'header header header header' 
    'left   right  right  right';
  /* grid-column-gap: 10px; - if you want gap between the columns */
} 

.left,
.right {
  padding: 3px;
  height: auto; /* Should be removed. Only for demonstration */
}

/* Style the left column */
.left {
  grid-area: left;
}

/* Style the right column */
.right {
  grid-area: right;
}

.grid-container2 {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
}

.grid-container2 > div {
  background-color: /*rgba(255, 255, 255, 0.8)*/ white;
  text-align: center;
  padding: 0px 0;
  font-size: 22px;
}

</style>

