<template>
  <header>
    <table>
      <tr>
        <td>
          <button
            :class="{ highlight: current_search=='mostrecent' }"
            @click="set_search_handler('mostrecent')">Most Recent
          </button>
        </td>
        <td style="text-align:right">
          <input type="text" v-model="name_target" placeholder="Enter name">
        </td>
        <td style="text-align:left">
          <button 
            :class="{ highlight: current_search=='byname' }"
            @click="set_search_handler('byname')">Search Name
          </button>
        </td>
        <td style="text-align:right">
          <datepicker-lite
            :value-attr="date_target"
            :locale="date_picker_config.locale"
            @value-changed="change_date_handler">
          </datepicker-lite>          
        </td>
        <td style="text-align:left">
          <button 
            :class="{ highlight: current_search=='bydate' }"
            @click="set_search_handler('bydate')">Search Date
          </button>
        </td>
      </tr>
    </table>
  </header>
</template>

<script>

import DatepickerLite from 'vue3-datepicker-lite';
import { Validator } from  '@/classes/Utils/Validator.js';
import { DateUtils } from  '@/classes/Utils/DateUtils.js';

import { ref, } from 'vue'

export default {

  components: {DatepickerLite},

  setup(_, { emit }) {

    const current_search = ref('');

      const name_target = ref('');

      const date_target = ref(DateUtils.offsetedDay(0, 'yyyymmdd'));

      let date_picker_config = DateUtils.date_picker_config;

      function change_date_handler (value) {
          date_target.value = value;
      }

      function set_search_handler(search_by) {

        // this is needed to highight the current search
        current_search.value = search_by;

        if ( search_by === 'byname' ) {
          if ( !Validator.validate(
                          'name to search', 
                          name_target.value, 
                          {type:"string", minlength:4, maxlength:20}
              )) {
            return;
          }
        }

        switch (search_by) {
          case 'mostrecent': 
            emit('set-search', {search_by, search_target:''});
            break;
          case 'byname': 
            emit('set-search', {search_by, search_target: name_target.value}); 
            break;
          case 'bydate': 
            emit('set-search', {search_by, search_target: DateUtils.toYyyyMmDd(date_target.value)}); 
            break;
        }

      }

      return {  name_target, date_target, date_picker_config, 
                change_date_handler, 
                set_search_handler, current_search }
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}
header {
  width: 100%;
  height: auto;
  background-color: purple;
}

nav {
  height: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 1rem;
}

button {
  font: inherit;
  /*background: transparent;*/
  background-color: blue;
  /*border: 1px solid transparent;*/
  border: 1px solid black;
  cursor: pointer;
  color: white;
  padding: 0.1rem 0.5rem;
  display: inline-block;
}

button:hover,
button:active {
  color: #f1a80a;
  border-color: #f1a80a;
  background-color: green;
}

span.sameline {
  display: inline-block; /* the default for span */
  width: 100px;
  height: 100px;
  padding: 5px;
  border: 1px solid blue;  
  background-color: yellow; 
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196F3;
  padding: 2px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 2px;
  font-size: 22px;
  text-align: center;
}

.highlight  {
  border-color: 1px solid white;
  background-color: white;
  color: red;
}


</style>