<template>
    <!--<div v-if="current_data != undefined">-->

  <!-- 
        21-03-21 modified 

  -->
  
      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
              <h3 v-if="current_mode=='add'">Adding New Direct Booking</h3>
              <h3 v-else-if="current_mode=='modify'">Modify Direct Booking</h3>
              <h3 v-else>Cancel Direct Booking</h3>
              <button
                type="button"
                class="btn-close"
                @click="$emit('close')"
                aria-label="Close modal"
              >
              &nbsp;&nbsp;&nbsp;x
              </button>

            </header>

            <section class="modal-body">
              
              <div class="grid-container">
                <div class="line1_item1">
                  <div class="inside-container">
                    <div class="inside-div-label">checkin
                    </div>
                    <div class="inside-div-input">
                      <datepicker-lite
                          width="100%"
                          :value-attr="input_fields.indate"
                          :locale="date_picker_config.locale"
                          @value-changed="change_indate">
                      </datepicker-lite>
                    </div>
                  </div>
                </div>              
              
                <div class="line1_item2">
                  <div class="inside-container">
                    <div class="inside-div-label"> checkout
                    </div>
                    <div class="inside-div-input">
                        <datepicker-lite
                          :value-attr="input_fields.outdate"
                          :locale="date_picker_config.locale"
                          @value-changed="change_outdate">
                        </datepicker-lite>
                    </div>
                  </div>
                </div>
                
                <div class="line2_item1">
                  <div class="inside-container">
                    <div class="inside-div-label">구분
                    </div>
                    <div class="inside-div-input">
                        <select v-model="input_fields.agent">
                            <option disabled value="">select</option>
                            <option v-for="(value,index) in agent_options" 
                              :key="index"
                              :value="value">{{ value }}</option>
                        </select>
                    </div>
                  </div>
                </div>
                
                <div class="line2_item2">
                  <div class="inside-container">
                    <div class="inside-div-label">room
                    </div>
                    <div class="inside-div-input">
                        <select v-model="input_fields.roomtype">
                          <option disabled value="">select</option>
                          <option v-for="(value,index) in room_options" 
                              :key="index"
                              :value="value">{{ value }}</option>
                        </select>                    
                    </div>
                  </div>
                </div>
                
                <div class="line2_item3">
                  <div class="inside-container">
                    <div class="inside-div-label">n guests
                    </div>
                    <div class="inside-div-input">
                        <input type="number" v-model.number="input_fields.nguests" placeholder="Enter no of guests">
                    </div>
                  </div>
                </div>
                <div class="line2_item4">
                  <div class="inside-container">
                    <div class="inside-div-label">breakfast
                    </div>
                    <div class="inside-div-input">
                      <select v-model="input_fields.breakfast">
                          <option value='no' selected>no</option>
                          <option value='yes'>yes</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div class="line3_item1">
                  <div class="inside-container">
                    <div class="inside-div-label">guest
                    </div>
                    <div class="inside-div-input">
                        <input type="text" v-model="input_fields.guest" placeholder="Enter guest name">                      
                    </div>
                  </div>
                </div>
                <div class="line3_item2">
                  <div class="inside-container">
                    <div class="inside-div-label">price
                    </div>
                    <div class="inside-div-input">
                        <input type="number" v-model.number="input_fields.price" placeholder="Enter price">
                    </div>
                  </div>
                </div>
                <div class="line4_item1">
                  <div class="inside-container">
                    <div class="inside-div-label">phone
                    </div>
                    <div class="inside-div-input">
                    <input type="text" v-model="input_fields.phone" placeholder="Enter guest phone number">
                    </div>
                  </div>
                </div>
                <div class="line4_item2">
                  <div class="inside-container">
                    <div class="inside-div-label">email
                    </div>
                    <div class="inside-div-input">
                    <input type="text" v-model="input_fields.email" placeholder="Enter guest email">
                    </div>
                  </div>
                </div>
              <div class="line5_item1">
                    <div class="inside-container">
                    <div class="inside-div-label">country
                    </div>
                    <div class="inside-div-input">
                        <input type="text" v-model="input_fields.country" placeholder="Enter guest country">
                    </div>
                  </div>
                </div>
                <div class="line5_item2">
                  <div class="inside-container">
                    <div class="inside-div-label">memo
                    </div>
                    <div class="inside-div-input">
                        <input type="text" v-model="input_fields.memo" placeholder="Enter note">
                    </div>
                  </div>
                </div>
                <div class="line5_item3">
                  <div class="inside-container">
                    <div class="inside-div-label">staff
                    </div>
                    <div class="inside-div-input">
                      <input type="text" v-model="input_fields.staff" placeholder="Enter staff initial">
                    </div>
                  </div>
                </div>
              </div>
              
            </section>

            <footer class="modal-footer">
              <div v-if="current_mode=='add'">
                <button type="button" class="btn-blue" @click="myFormAction('add')">
                  Make New Booking
                </button>
              </div>
              <div v-if="current_mode=='modify'">
                <button type="button" class="btn-blue" @click="myFormAction('modify')">
                  Modify Booking
                </button>
              </div>
              <div v-if="current_mode=='cancel'">
                <button type="button" class="btn-blue" @click="myFormAction('cancel')">
                  Cancel Booking
                </button>
              </div>
              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>
            </footer>
          </div>
        </div>
      </div>
</template>

<script>
import DatepickerLite from 'vue3-datepicker-lite';
import { Validator } from  '@/classes/Utils/Validator.js';
import { DateUtils } from  '@/classes/Utils/DateUtils.js';
import { RoomDefs } from  '@/classes/Utils/RoomDefs.js';
import { AgentDefs } from  '@/classes/Utils/AgentDefs.js';
import { bookingform } from '@/classes/BookingForm.js';
import { searchbooking } from '@/classes/SearchBooking.js';
import { accounting } from '@/classes/Accounting.js';

import { ref, onMounted, } from 'vue';

export default {
  name: 'BookingForm',
  props: [ 'current_data', 'current_application', 'current_mode', ],
  components: {DatepickerLite},
  setup(props, { emit }) {
    
    let room_options = RoomDefs.room_list;
    let agent_options = AgentDefs.agent_list;
    
    let date_picker_config = DateUtils.date_picker_config;
    
    let payload;
    
    const input_fields = ref({
      indate : DateUtils.toPickerFormat(DateUtils.offsetedDay(0, 'yyyymmdd')),
      outdate: DateUtils.toPickerFormat(DateUtils.offsetedDay(1, 'yyyymmdd')),
      agent:'',
      roomtype:'',
      nguests:0,
      breakfast:'no',
      price:0,
      guest:'',
      phone:'',
      email:'',
      country:'',
      memo:'',
      staff:'',
    });

    onMounted(() => {

      switch (props.current_mode) {
          case 'add':
            break;
          case 'modify':
          case 'cancel':
            load_input_fields(props.current_data);
            break;
      }

    });

    function load_input_fields(current_data) {
      input_fields.value.indate    = DateUtils.toPickerFormat(current_data.indate);
      input_fields.value.outdate   = DateUtils.toPickerFormat(current_data.outdate);
      input_fields.value.agent     = current_data.agent;
      input_fields.value.roomtype  = current_data.roomtype;
      input_fields.value.nguests   = current_data.nguests;
      input_fields.value.breakfast = current_data.breakfast;
      input_fields.value.price     = current_data.price;
      input_fields.value.guest     = current_data.guest;
      input_fields.value.phone     = current_data.phone;
      input_fields.value.email     = current_data.email;
      input_fields.value.country   = current_data.country;
      input_fields.value.memo      = current_data.memo;
      input_fields.value.staff     = current_data.staff;
    }

    function checkInputs(mode) {
      // modify or cancel ====  no checking
      if (mode==='add') {
        if (!verifyCheckin()) return false;
        if (!verifyCheckout()) return false;
        if (!verifyInput()) return false;
      }
      return true;
    }

    function verifyInput() {
      if (!Validator.validate('agent', input_fields.value.agent, {type:"one_in_array", array:agent_options})) return false;
      if (!Validator.validate('roomtype', input_fields.value.roomtype, {type:"one_in_array", array:room_options})) return false;
      if (!Validator.validate('nguests', input_fields.value.nguests, {type:"number", meet:"zero_plus"})) return false;
      if (!Validator.validate('breakfast', input_fields.value.breakfast, {type:"one_in_array", array:['yes', 'no']})) return false;
      if (!Validator.validate('guest', input_fields.value.guest, {type:"string", minlength:5, maxlength:20})) return false;
      if (!Validator.validate('price', input_fields.value.price, {type:"number", meet:"zero_plus"})) return false;
      if (!Validator.validate('staff', input_fields.value.staff, {type:"string", minlength:2, maxlength:10})) return false;
      
      return true;
    }

    function verifyCheckin() {
      let today = DateUtils.offsetedDay(0, 'yyyymmdd');     // today
      let ndays = DateUtils.diffBetweenPickerDays(DateUtils.toPickerFormat(today), input_fields.value.indate);
      if (ndays<0) {
        alert ('checkin must be today or later. try again');
        return false;
      }
      return true;
    }

    function verifyCheckout() {
      let ndays = DateUtils.diffBetweenPickerDays( input_fields.value.indate, input_fields.value.outdate );
      if (ndays<=0) {
        alert ('invalid checkout date. try again');
        return false;
      }
      return true;
    }

    async function myFormAction(mode) {  

      // check validity of input values
      if (!checkInputs(mode)) return false;

      prepareArgs(mode);
        
      switch (mode) {
        case 'add':
          await bookingform.add_booking(payload);
          break;
        case 'modify':
          await bookingform.modify_booking(payload);
          if (props.current_application=='accounting')
            accounting.refetch();
          else
            searchbooking.refetch();
          emit('close');
          break;
        case 'cancel':
          await bookingform.cancel_booking(payload.dataid);
          if (props.current_application=='accounting')
            accounting.refetch();
          else
            searchbooking.refetch();
          emit('close');
      }
    }
    
    function prepareArgs(mode) {
      payload = {
        indate :      DateUtils.toYyyyMmDd(input_fields.value.indate),
        outdate :     DateUtils.toYyyyMmDd(input_fields.value.outdate),
        agent :       input_fields.value.agent,
        roomtype :    input_fields.value.roomtype,
        nguests :     input_fields.value.nguests,
        breakfast :   input_fields.value.breakfast,
        price :       input_fields.value.price,
        guest :       input_fields.value.guest,
        phone :       input_fields.value.phone,
        email :       input_fields.value.email,
        country :     input_fields.value.country,
        memo :        input_fields.value.memo,
        staff :       input_fields.value.staff,
        nights:       DateUtils.diffBetweenPickerDays(input_fields.value.indate, input_fields.value.outdate),
        rdetail:      '/' + input_fields.value.roomtype + ':' + input_fields.value.nguests
      }
      if (mode!='add') {
        payload.dataid  = props.current_data.dataid;
      }
    }
    
    function change_indate(value) {
      input_fields.value.indate = value;
    }

    function change_outdate(value) {
      input_fields.value.outdate = value;
    }
 
    return { input_fields, room_options, agent_options, 
              date_picker_config, change_indate, change_outdate, 
              bookingform, myFormAction, };

  },

}
</script>

<style scoped>



  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    background-color: rgb(236, 203, 154);
    /*background-color: white;*/
    border-radius: 5px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    /*color: white;*/
    color: black;
    justify-content: space-between; 
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: 16.5% 16.5% 16.5% 16.5% 16.5% 16.5%;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
}
.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 0;
  font-size: 30px;
}

.inside-container {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
  margin: 0px 0px 0px 0px;
}
.inside-div-label {
  background-color: white;
  text-align: left;
  padding: 0; margin: 5px 1px 0px 1px;
  font-size: 15px;
  font-weight: bold;
}
.inside-div-input {
  background-color: white;
  text-align: left;
  padding: 0; margin: -1px 1px 0px 1px;
  font-size: 20px;
}

/*  line 1  */
.line1_item1 {
  grid-column: 1 / span 3;
}
.line1_item2 {
  grid-column: 4 / span 3;
}
/*  line 2  */
.line2_item1 {
  grid-column: 1 / span 2;
}
.line2_item2 {
  grid-column: 3 / span 1;
}
.line2_item3 {
  grid-column: 4 / span 1;
}
.line2_item4 {
  grid-column: 5 / span 2;
}

/*  line 3  */
.line3_item1 {
  grid-column: 1 / span 3;
}
.line3_item2 {
  grid-column: 4 / span 3;
}
/*  line 4  */
.line4_item1 {
  grid-column: 1 / span 3;
}
.line4_item2 {
  grid-column: 4 / span 3;
}

/*  line 5 */
.line5_item1 {
  grid-column: 1 / span 2;
}
.line5_item2 {
  grid-column: 3 / span 3;
}
.line5_item3 {
  grid-column: 6 / span 1;
}

input {
  width: 100%;
}
select {
  width: 100%;
}

.item_label { grid-colum: 1 / span 1; }
.item_input { grid-colum: 1 / span 1; }

</style>