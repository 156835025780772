<template>

  <div class="grid-container">
      <div @click="datechange(-7)"><img src="double_red_left.gif"></div>
      <div @click="datechange(-1)"><img src="red_left_arrow.gif"></div>
      <div @click="datechange(1)"><img src="green_right_arrow.gif"></div>
      <div @click="datechange(7)"><img src="double_green_right.gif"></div>
      <div>
        <datepicker-lite id="checkin"
            width="50px"
            :value-attr="mydateselected"
            :class-attr="pickerConfig.class"
            :locale="pickerConfig.locale"
            @value-changed="changeDateEvent">
        </datepicker-lite>
      </div>
      <div>
        <button @click="setToToday">Go Today</button>
      </div>      
      <div>
        <!--<h3 style="vertical-align: top; background-color: green; color: white;margin:0px; padding:0px;">{{ myDate }}-->
        <h3 style="vertical-align: top; background-color: green; color: white;margin:0px; padding:0px;">{{ DateUtils.display_date_for_accounting(myDate, 'jp') }}
        </h3>
      </div>
  </div>

</template>

<script>
  import {DateUtils} from '@/classes/Utils/DateUtils.js';
  import {accounting} from '@/classes/Accounting.js';
  
  import DatepickerLite from 'vue3-datepicker-lite';
  import { ref } from 'vue'
	export default {
    components: { DatepickerLite, //
    },
    props: [],
    setup(_, { emit }) {

        //const open_pdf_create_form = ref(false);

        const myDate = ref(DateUtils.offsetedDay(0, 'yyyymmdd'))
        const dateOffset = ref(0)
        const mydateselected = ref( DateUtils.toPickerFormat(DateUtils.offsetedDay(0, 'yyyymmdd')) )
        const pickerConfig = ref( {
          class: "width: 50px;",
          locale: {
            format: "YYYY/MM/DD",
            weekday: ["sun", "mon", "tue", "wed", "thr", "fri", "sat"],
            startsWeeks: 0,
            todayBtn: "TODAY",
            clearBtn: "Clear",
            closeBtn: "Close"
          }
        } )

        // change by date picker
        const changeDateEvent  = (arg) => {
            mydateselected.value = arg;
            myDate.value = DateUtils.toYyyyMmDd(arg);
            accounting.set_account_date(myDate.value);
            dateOffset.value = DateUtils.diffBetweenDays(DateUtils.offsetedDay(0, 'yyyymmdd'), myDate.value);
            emit('date-selected', dateOffset.value)
        }

        // date change by clicking arrows
        function datechange( shift ) {
          dateOffset.value += Number(shift);
          myDate.value = DateUtils.offsetedDay(dateOffset.value, 'yyyymmdd');
          accounting.set_account_date(myDate.value);
          emit('date-selected', dateOffset.value)
        }

        // date change to today
        function setToToday() {
          dateOffset.value = 0;
          myDate.value = DateUtils.offsetedDay(dateOffset.value, 'yyyymmdd');
          accounting.set_account_date(myDate.value);
          emit('date-selected', dateOffset.value)
        }

        return { changeDateEvent, mydateselected, setToToday, datechange, pickerConfig, DateUtils, myDate, 
        }

    },
};
</script>

<style scoped>

/* The grid container */
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
  vertical-align: top;
} 

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  /*border: 1px solid black;*/
  text-align: center;
  font-size: 22px;
  vertical-align: top;
}
</style>

