<template>
  <div :class="{login: mode==='login'}"></div>
    <dialog open>
      <header>
        <slot name="header">
          <h1>{{  title  }}</h1>
        </slot>
      </header>
      <section>
        <slot name="main"></slot>
      </section>
      <menu>
        <slot name="actions">
          <base-button @click="$emit('close')">Close</base-button>
        </slot>
      </menu>
    </dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    mode: {type: String, required: true}
  },
  emits: ['close'],
};
</script>

<style scoped>
.login {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 3vh;
  /*left: 20%;*/
  /*width: 80%;*/
  width: 500px;
  z-index: 100;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  height: 55px;
  padding: 1px 0.3rem 1rem 1.5rem;
  margin: 0px 0px 0px 0px;
}

header h2 {
  margin: 0px;
  vertical-align: top;
  margin: auto;
  text-align: center;
  font-size: 25px;
}

header h1 {
  margin: 0px;
  vertical-align: top;
  margin: auto;
  text-align: center;
  font-size: 30px;
}

section {
  padding: 1rem;
}

menu {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px/*768px*/) {
  dialog {
    /*left: calc(50% - 20rem);
    width: 40rem;*/
    left: calc(20% - 20rem);
    width: 550px;
  }
}


</style>