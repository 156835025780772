<template>
      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
              <h3 width=100% class="text-center">Counting Money</h3>
              <button
                type="button"
                class="btn-close"
                @click="$emit('close')"
                aria-label="Close modal"
              >
              &nbsp;&nbsp;&nbsp;x
              </button>

            </header>

            <section class="modal-body">

              <div class="grid-container">

                <div class="inputFieldTop1">10,000 yen</div>
                <div class="inputFieldTop2">5,000 yen</div>

                <div class="inputFieldBot1">
                    <input @change="onChangeValue()" type="number" v-model="inputArgs.tenthousands">
                </div>

                <div class="inputFieldBot2">
                    <input @change="onChangeValue()" type="number" v-model="inputArgs.fivethousands">
                </div>

                <div class="inputFieldTop1">2,000 yen</div>
                <div class="inputFieldTop2">1,000 yen</div>

                <div class="inputFieldBot1">
                    <input @change="onChangeValue()"  type="number" v-model="inputArgs.twothousands">
                </div>
                <div class="inputFieldBot2">
                    <input @change="onChangeValue()" type="number" v-model="inputArgs.onethousands">
                </div>

                <div class="inputFieldTop1">500 yen</div>
                <div class="inputFieldTop2">100 yen</div>

                <div class="inputFieldBot1">
                    <input @change="onChangeValue()"  type="number" v-model="inputArgs.fivehundreds">
                </div>
                <div class="inputFieldBot2">
                    <input @change="onChangeValue()" type="number" v-model="inputArgs.onehundreds">
                </div>

                <div class="inputFieldTop1">50 yen</div>
                <div class="inputFieldTop2">10 yen</div>

                <div class="inputFieldBot1">
                    <input @change="onChangeValue()"  type="number" v-model="inputArgs.fifty">
                </div>
                <div class="inputFieldBot2">
                    <input @change="onChangeValue()" type="number" v-model="inputArgs.ten">
                </div>

                <div class="inputFieldTop1">5 yen</div>
                <div class="inputFieldTop2">1 yen</div>

                <div class="inputFieldBot1">
                    <input @change="onChangeValue()"  type="number" v-model="inputArgs.five">
                </div>
                <div class="inputFieldBot2">
                    <input @change="onChangeValue()" type="number" v-model="inputArgs.one">
                </div>

              </div>

            </section>

            <footer class="modal-footer">
              <h2>Total = {{ inputArgs.total }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
              <button type="button" class="btn-blue" @click="myformaction">
                Save Money-Counting
              </button>
              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>
            </footer>
          </div>
        </div>
      </div>
</template>

<script>
import { ref, computed, onMounted, } from 'vue';
import { accounting } from  '@/classes/Accounting.js';

export default {
  props: [ 'oldMoneyCounting', 'myAccountDate' ],

  components: {},
  setup(props, { emit }) {

      const account_date = computed(() => accounting.get_account_date() );
      const inputArgs = ref( {
          tenthousands : 0,
          fivethousands : 0,
          twothousands : 0,
          onethousands : 0,
          fivehundreds : 0,
          onehundreds : 0,
          fifty : 0,
          ten : 0,
          five : 0,
          one : 0,
          total : 0
      });

      onMounted(() => { 
          inputArgs.value.tenthousands = props.oldMoneyCounting.tenthousands;
          inputArgs.value.fivethousands = props.oldMoneyCounting.fivethousands;
          inputArgs.value.twothousands = props.oldMoneyCounting.twothousands;
          inputArgs.value.onethousands = props.oldMoneyCounting.onethousands;
          inputArgs.value.fivehundreds = props.oldMoneyCounting.fivehundreds;
          inputArgs.value.onehundreds = props.oldMoneyCounting.onehundreds;
          inputArgs.value.fifty = props.oldMoneyCounting.fifty;
          inputArgs.value.ten = props.oldMoneyCounting.ten;
          inputArgs.value.five = props.oldMoneyCounting.five;
          inputArgs.value.one = props.oldMoneyCounting.one;
          inputArgs.value.total = props.oldMoneyCounting.total;
       });

      function onChangeValue() {
        var temp = Number(0);
        temp =  temp + Number(10000) * inputArgs.value.tenthousands + 
                Number(5000) * inputArgs.value.fivethousands  +
                Number(2000) * inputArgs.value.twothousands   +
                Number(1000) * inputArgs.value.onethousands   +
                Number(500) * inputArgs.value.fivehundreds   +
                Number(100) * inputArgs.value.onehundreds    +
                Number(50) * inputArgs.value.fifty          +
                Number(10) * inputArgs.value.ten            +
                Number(5) * inputArgs.value.five           +
                Number(1) * inputArgs.value.one;

        inputArgs.value.total = temp;
      }
      
      function get_total() {
        var temp = Number(0);
        temp =  temp + Number(10000) * inputArgs.value.tenthousands + 
                Number(5000) * inputArgs.value.fivethousands  +
                Number(2000) * inputArgs.value.twothousands   +
                Number(1000) * inputArgs.value.onethousands   +
                Number(500) * inputArgs.value.fivehundreds   +
                Number(100) * inputArgs.value.onehundreds    +
                Number(50) * inputArgs.value.fifty          +
                Number(10) * inputArgs.value.ten            +
                Number(5) * inputArgs.value.five           +
                Number(1) * inputArgs.value.one;

        return temp;
      }

      async function myformaction() {
        if (inputArgs.value.tenthousands === "") { alert ('10,000 yen counting is not entered. try again'); return}
        if (inputArgs.value.fivethousands === "") { alert ('5,000 yen counting is not entered. try again'); return}
        if (inputArgs.value.twothousands === "") { alert ('2,000 yen counting is not entered. try again'); return}
        if (inputArgs.value.onethousands === "") { alert ('1,000 yen counting is not entered. try again'); return}
        if (inputArgs.value.fivehundreds === "") { alert ('500 yen counting is not entered. try again'); return}
        if (inputArgs.value.onehundreds === "") { alert ('100 yen counting is not entered. try again'); return}
        if (inputArgs.value.fifty === "") { alert ('50 yen counting is not entered. try again'); return}
        if (inputArgs.value.ten === "") { alert ('10 yen counting is not entered. try again'); return}
        if (inputArgs.value.five === "") { alert ('5 yen counting is not entered. try again'); return}
        if (inputArgs.value.one === "") { alert ('1 yen counting is not entered. try again'); return}
        //if (confirm('are you sure about saving the entry')===false) return;
        var myobj = { 
          tenthousands : inputArgs.value.tenthousands,
          fivethousands : inputArgs.value.fivethousands,
          twothousands : inputArgs.value.twothousands,
          onethousands : inputArgs.value.onethousands,
          fivehundreds : inputArgs.value.fivehundreds,
          onehundreds : inputArgs.value.onehundreds,
          fifty : inputArgs.value.fifty,
          ten : inputArgs.value.ten,
          five : inputArgs.value.five,
          one : inputArgs.value.one,
          total: get_total(),
          mydate : props.myAccountDate
        };
        console.log(myobj);
      await accounting.update_money_counting(myobj); // just update DB
      await accounting.fetch_data(account_date.value);

        emit('close');
    }

    return { inputArgs, onChangeValue, myformaction, };

  },
}
</script>

<style scoped>
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    background-color: rgb(136, 85, 9);
    border-radius: 5px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: white;
    justify-content: space-between; 
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0px;
  background-color: white;
  padding: 1px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 5px 0;
  font-size: 18px;
}

.inputFieldTop1 {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: -5px;
}
.inputFieldTop2 {
  grid-column-start: 2;
  grid-column-end: 3;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: -5px;
}

/*  bottom */
.inputFieldBot1 {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-top: -10px;
}
.inputFieldBot2 {
  grid-column-start: 2;
  grid-column-end: 3;
  margin-top: -10px;
}

</style>