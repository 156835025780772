import { createApp } from 'vue';

//import store from './store/index.js';
import App from './App.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import ErrorDialog from './components/ui/ErrorDialog.vue';
import BookingForm2 from './components/layouts/BookingForm2.vue';
import InfoForm from './components/layouts/InfoForm.vue';
import ToastWindow from './components/layouts/ToastWindow.vue';
import BaseAccountingForm from './components/ui/BaseAccountingForm.vue';
import BookingSummary from './components/layouts/BookingSummary.vue';
import PaymentHistory from './components/layouts/PaymentHistory.vue';

const app = createApp(App)

app.component('base-badge', BaseBadge);
app.component('base-card', BaseCard);
app.component('base-dialog', BaseDialog);
app.component('base-button', BaseButton);
app.component('error-dialog', ErrorDialog);
app.component('booking-form2', BookingForm2);
app.component('toast-window', ToastWindow);
app.component('info-form', InfoForm);
app.component('base-accounting-form', BaseAccountingForm);
app.component('booking-summary', BookingSummary);
app.component('payment-history', PaymentHistory);
app.component('base-spinner', BaseSpinner);

app.mount('#app');
