import { ref } from 'vue';

import { PmsAccess, } from '@/classes/DbAccess/PmsAccess.js';

class UserInfo {
    constructor() {
        
        this.isLoggedIn = ref(false);
        this.username = ref('');
        this.userrole = ref('');

        this.body = {};

        this.current_component = ref('');
        
    }

    set_isLoggedIn(param) { this.isLoggedIn.value = param; }
    set_username(param) { this.username.value = param; }
    set_userrole(param) { this.userrole.value = param; }
    set_current_component(param) { this.current_component.value = param; }

    get_username() { return this.username.value; }
    get_userrole() { return this.userrole.value; }
    get_isLoggedIn() { return this.isLoggedIn.value; }
    get_current_component() { return this.current_component.value; }

    /*
        for security reason, we use "POST" instead of "GET"
        
        /api/login
        body: {loginid, password}
    */
    async check_login(loginid, password) {
        let dbaccess = new PmsAccess();
        let data = {loginid, password};
        await dbaccess.fetch_pms("Login", 'login', data);

        // this.pms_response is the property of parent 'PmsAccess'
        if (dbaccess.pms_response.type == 'success') {
            this.isLoggedIn.value = true;
            this.username.value = dbaccess.pms_response.response.username;
            this.userrole.value = dbaccess.pms_response.response.role;
        }
    }
}

export let userinfo = new UserInfo();
