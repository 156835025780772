<template>
  <button :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['mode']
}
</script>

<style scoped>

/*   button classes   */
button {
  font: inherit;
  border: 1px solid #0076bb;
  background-color: #0076bb;
  color: white;
  cursor: pointer;
  padding: 0px;
  padding-left: 4px;
  padding-right: 3px;
  padding-bottom: 3px;
  border-radius: 1px;  
}

.red {
  border: 1px solid red;
  background-color: red;
}

.green {
  border: 1px solid green;
  background-color: green;
}

.orange {
  border: 1px solid cyan;
  background-color: cyan;
  color: black;
}

.blue {
  border: 1px solid blue;
  background-color: blue;
}

.purple {
  border: 1px solid purple;
  background-color: purple;
}

.grey {
  border: 1px solid lightgrey;
  background-color: lightgrey;
  color: black;
}

.small {
  font-size: 15px;
  vertical-align: middle;
  padding: auto;
  margin: auto;
}
.medium {
  font-size: 18px;
  vertical-align: middle;
  padding: auto;
  margin: auto;
}
.large {
  font-size: 21px;
  vertical-align: middle;
  padding: auto;
  margin: auto;
}

button:hover,
button:active {
  border-color: blue;
  background-color: blue;
}

button.purple_button {
  background-color:purple;
  vertical-align: middle;
}

.flat {
  background-color: transparent;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #edd2ff;
}
</style>