<template>
  <header>
    <nav>
      <ul>
        <li>
          <button 
            :class="{ highlight: current_component=='add-booking' }"  
            @click="set_active_page('add-booking')">AddBooking
          </button>
        </li>
        <li>
          <button 
            :class="{ highlight: current_component=='search-booking' }" 
            @click="set_active_page('search-booking')">SearchBooking
          </button>
        </li>
        <li>
          <button 
            :class="{ highlight: current_component=='assign-room' }" 
            @click="set_active_page('assign-room')">AssignRoom
          </button>
        </li>
        <li>
          <button 
            :class="{ highlight: current_component=='accounting' }" 
            @click="set_active_page('accounting')">Accounting
          </button>
        </li>
        <li>
          <button 
            :class="{ highlight: current_component=='quotation' }" 
            @click="set_active_page('quotation')">Quotation
          </button>
        </li>
        <li>
          <button 
            :class="{ highlight: current_component=='information' }" 
            @click="set_active_page('information')">Information
          </button>
        </li>
        <li>
          <button 
            :class="{ highlight: current_component=='sales-stats' }" 
            @click="set_active_page('sales-stats')">Sales Stats
          </button>
        </li>
      
      </ul>
    </nav>
  </header>
</template>

<script>
import { userinfo } from '@/classes/UserInfo.js';
import { ref } from 'vue';
export default {
  setup() {

    const current_component = ref('');

    function set_active_page(page) {
      
      // save active component name for local use
      current_component.value = page;
      // also save in userinfo as well
      userinfo.set_current_component(page);
    }

    return { set_active_page, current_component};
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: 3rem;
  background-color: #11005c;
  padding: 5px;
}

nav {
  height: 100%;
}

ul {
  list-style: none;
  margin: 3px;
  padding: 1px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  font-size: 1.5rem;
}

button {
  font: inherit;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  padding: 0.1rem 0.5rem;
  display: inline-block;
}

button:hover,
button:active {
  color: #f1a80a;
  border-color: #f1a80a;
  background-color: #1a037e;
}

.highlight  {
  border-color: 1px solid white;
  background-color: white;
  color: red;
}
</style>