<template>
    <div class="my_title">Booking</div>
        
    <table class="mytable">
        <tr>
          <th class="my_th">guest</th>
          <td class="my_td">{{ current_data.guest }}</td>
        </tr>
        <tr>
          <th class="my_th">Status</th>
          <td class="my_td">{{ current_data.kind }}</td>
        </tr>
        <tr>
          <th class="my_th">agent</th>
          <td class="my_td">{{ current_data.agent }}</td>
        </tr>
        <tr>
          <th class="my_th">checkin</th>
          <td class="my_td">{{ DateUtils.display_date(current_data.indate, 'y_m_d') }}</td>
        </tr>
        <tr>
          <th class="my_th">checkout</th>
          <td class="my_td">{{ DateUtils.display_date(current_data.outdate, 'y_m_d') }}</td>
        </tr>
        <tr>
          <th class="my_th">roomtype</th>
          <td class="my_td">{{ current_data.roomtype }}</td>
        </tr>
        <tr>
          <th class="my_th">n guests</th>
          <td class="my_td">{{ current_data.nguests }}</td>
        </tr>
        <tr>
          <th class="my_th">price</th>
          <td class="my_td">{{ FormatUtils.display_money(current_data.price) }}</td>
        </tr>
        <tr>
          <th class="my_th">phone</th>
          <td class="my_td">{{ current_data.phone }}</td>
        </tr>
    </table>
</template>

<script>
import { DateUtils } from  '@/classes/Utils/DateUtils.js';
import { FormatUtils } from  '@/classes/Utils/FormatUtils.js';

export default {

    name: 'BookingSummary',
    props: [ 'current_data'],
    setup() {

        return { DateUtils, FormatUtils, };

    }

};
</script>


<style>
.booking-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 0px;
  background-color: #001a33;
  padding: 0px;
  text-align: left;
}
/*  booking  */
.booking_long_item {
  grid-column: 1 / span 2;
  color: yellow;
  font-weight: bold;
  font-size: 18px;
}
.booking_left_item {
  grid-column: 1 / span 1;
  color: lightgray;
  font-size: 15px;
}
.booking_right_item {
  grid-column: 2 / span 1;
  color: lightgray;
  font-size: 18px;
}

.my_th {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: lightgray;
  /*border-right: 4px dotted lightgray;  */
  /*border-right: 4px single lightgray;  */
  padding: 0px 0px 0px 3px;
  background-color: #001a33;
}
.my_td {
  text-align: left;
  font-weight: 100;
  font-size: 15px;
  color: lightgray;
  padding: 0px 0px 0px 3px;
  background-color: #001a33;
}

.my_title {
  color: yellow;
  font-weight: bold;
  font-size: 18px;
}



</style>
