<template>
      <h2>{{ search_result_title }}</h2>
      <table>
        <tr class="bigtitle">
          <th>agent</th><th>status</th><th>guestname</th><th>act</th>
          <th>price</th><th>prepaid</th><th>NRFND</th><th>indate</th><th>outhate</th><th>N</th>
          <th>room</th><th>guests</th>
          <th>breakfast</th>
        </tr>
        <tbody v-for="(booking, index) in booking_list" :key="index">
            <tr :class="[ DateUtils.backColorForDate(booking.booking.indate) ]">
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.agent }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.kind }}</td> 
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.guest }}</td>
              <td>
                  <base-button class="small green" @click="item_click_handler(index)">act</base-button>
              </td>
              <td :class="DateUtils.foreColor(booking.booking.kind)" class="money">{{ FormatUtils.display_money(booking.booking.price) }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.prepaid=='1' ? 'yes' : '' }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.nonrefundable=='1' ? 'yes' : '' }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ DateUtils.display_date(booking.booking.indate, 'y_m_d') }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ DateUtils.display_date(booking.booking.outdate, 'y_m_d') }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.nights }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.roomtype }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)" width=2%>{{ booking.booking.nguests===undefined ? '??' : booking.booking.nguests }}</td>
              <td :class="DateUtils.foreColor(booking.booking.kind)">{{ booking.booking.breakfast=='yes' ? 'yes' : '' }}</td>
          </tr>
        </tbody>

      </table>
  
  <action-menu
      v-if="open_action_menu"
      @close="open_action_menu=false"
  >
  </action-menu>

</template>

<script>
import ActionMenu from './ActionMenu.vue';

import { DateUtils } from  '@/classes/Utils/DateUtils.js';
import { FormatUtils } from  '@/classes/Utils/FormatUtils.js';
import { searchbooking } from '@/classes/SearchBooking.js';
import { ref, computed } from 'vue';

export default {
  components: { ActionMenu, },
  props: [ 'search_result_title',  ],

  setup() {

    const open_action_menu = ref(false);

    const booking_list = computed(() => { 
      return searchbooking.get_data(); 
    });

    // when a list is clicked, save the current data in the global "searchbooking" instance...
    // later action-menu will use the current data
    function item_click_handler(myindex) {
      searchbooking.set_current_data(myindex);
      open_action_menu.value = true;
    }

    return {  booking_list, item_click_handler, open_action_menu,
              DateUtils, FormatUtils, };

  },
  
};
</script>

<style scoped>
 
.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}

      /* most recent and rlist */
      .black_foreground {
        color: black;
      }
      .red_foreground {
        color: red;
      }
      .today {
        background-color: #9BF99E;
      }
      .onedayafter {
        background-color: #F0E68C;
      }
      .twodayafter {
        background-color: #ADD8E6;
      }
th {
  background: white;
  position: sticky;
  top: 0; 
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
     table {
  text-align: left;
  position: relative;
  border-collapse: collapse; 
}

.money {
   text-align: right;
   margin-left: 2px;
}
</style>