<template>
        <div>
            <h2 
                v-if="selection=='regular'"
                style="padding:0px; margin: 0px;">&nbsp;
                Regular Booking
            </h2>
            <h2 
                v-else
                style="padding:0px; margin: 0px;">&nbsp;
                Canceled Booking
            </h2>
        </div>

        <table  class='borderedtable'>
            <tr class="bigtitle">
                <th>O-A-N</th><th>guest</th><th>act</th><th>type</th>
                <th>price</th><th>room</th><th>残金</th>
            </tr>
            <tr v-for="(item,index) in myBookingList" v-bind:key="index">
                <td>
                    {{ getO_A_N(item.booking.agent, item.booking.outdate, item.booking.nights) }}
                </td>

                <td>
                    {{ item.booking.guest }}
                </td>

                <td> 
                    <base-button class="small green" @click="itemClicked(index)">act</base-button>
                </td>

                <td>
                    {{ item.booking.roomtype }}
                </td>

                <td class="money">
                    {{ FormatUtils.display_money(item.booking.price) + ' ' + add_icons(item.booking.prepaid, item.booking.nonrefundable, item.booking.breakfast) }}
                </td>

                <td v-bind:class="[item.booking.checkinstatus]">
                    {{ item.room }}
                </td>

                <td class="money" v-bind:class="[mypaystatus(item.booking.price, item.payentry)]">
                    {{ FormatUtils.display_money(item.booking.price-sumOfPaymentIncludingLoss(item.payentry)) }}
                </td>
            </tr>
            <tr class="total">
                <td class="total money">TOTAL</td>
                <td colspan=2></td>
                <td>
                    {{ myStats.totalIN }}
                </td>
                <td class="money total">
                    {{ FormatUtils.display_money(myStats.total_price) }}
                </td>
                <td colspan=1></td>
                <td class="money total">
                    {{ FormatUtils.display_money(myStats.total_balance) }}
                </td>
            </tr>
        </table>

    <!--
      when "ACT" is clicked here
      action-menu is opened. 
      'action-menu' does handles the menu selection there

      REMEMBER REMEMBER REMEMBER REMEMBER REMEMBER REMEMBER REMEMBER REMEMBER 
      
      currentData = { booking: {... }, payentry: [], roomassign: "", memo: "" }
    -->
    <action-menu 
        v-if="acActionMenu"
        @close="acActionMenu=false">
    </action-menu>

</template>
<script>


import ActionMenu from  './ActionMenu.vue';
import { FormatUtils } from  '@/classes/Utils/FormatUtils.js';
import { accounting } from '@/classes/Accounting.js';

import { ref, computed,  } from 'vue';
export default {
    name: 'DayBooking',
    components: { ActionMenu, }, 
    props: [ 'selection', ],

    setup(props) {

        const acActionMenu = ref(false);

        const myBookingList = computed(() => {
            switch (props.selection) {
                case 'regular':
                    return accounting.get_day_normal_booking();
                case 'cancel':
                    return accounting.get_day_cancel_booking();
            }
            return false;
        });

        const myStats = computed(() => {
            switch (props.selection) {
                case 'regular':
                    return accounting.get_stats_normal_day_booking();
                case 'cancel':
                    return accounting.get_stats_cancel_day_booking();
            }
            return false;
        });

        function itemClicked(myindex) {
            accounting.set_current_data(myBookingList.value[myindex]);
            acActionMenu.value = true;
        }

  function getO_A_N(agent, outdate, nights) {
    return outdate.substr(6, 2) + "-" + agent + " (" + nights + ")";
  }

  function mypaystatus(price, payentry) {
    var mybalance;
    mybalance = price - sumOfPaymentIncludingLoss(payentry);
    if (mybalance==0) return 'yes';
    else if (mybalance>0) return 'no';
    else return 'over';
  }

  function sumOfPaymentIncludingLoss(mypayentries) {
    var mytotal = Number(0);
    mypayentries.forEach(function (value) {
        mytotal += Number(value.amount);
    })
    return mytotal;
  }

  function add_icons(prepay, nonrefundable, breakfast) {
    var build="";
    if (prepay=='1') build+="💰";
    if (breakfast=='1') build+="🍴";
    if (nonrefundable=='1') build+="👎";      //   �🚫😍💰🅿🙅🈚🈲👎❌🔟 
    return build;
  }

        return { FormatUtils, itemClicked, add_icons,
                    getO_A_N, mypaystatus, sumOfPaymentIncludingLoss,
                    myBookingList, myStats, acActionMenu,
                       }

    },
};
</script>

<style scoped>
.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}


      .black {
        color: black;
      }
      .red {
        color: red;
      }

    .money {
        padding-right: 5px;
        text-align: right;
    }
    .yes {
        color:white;
        background-color: green;
      }
    .no {
        color: white;
        background-color: red;
      }



</style>