<template>
        <table  class='borderedtable'>
            <tr class="bigtitle">
                  <th>guest</th><th>checkin</th><th>時間</th><th>入金</th><th>項目</th><th>方法</th> <th>Memo</th> <th>Staff</th>
            </tr>
            <tr v-for="(value, index) in payentries" :key="index">

                  <td :class="itemclass">{{ value.guest }}</td>
                  <td :class="itemclass">{{ DateUtils.display_date(value.indate, 'y_m_d') }}</td>
                  <td :class="itemclass">{{ DateUtils.displayHourMinute(value.mydate) }}</td>
                  <td :class="itemclass" class="money">{{ FormatUtils.display_money(value.amount) }}</td>
                  <td :class="itemclass">{{ (value.major) }}</td>
                  <td :class="itemclass">{{ (value.minor) }}</td>
                  <td :class="itemclass">{{ (value.memo) }}</td>
                  <td :class="itemclass">{{ (value.staff) }}</td>
            </tr>
        </table>
</template>
<script>
import {FormatUtils} from  '@/classes/Utils/FormatUtils.js';
import {DateUtils} from  '@/classes/Utils/DateUtils.js';
export default {

    props: [ 'payentries', ],    // data received from the parent component (myaccounting)
    name: 'DayAccount',
    setup() {


      return { FormatUtils, DateUtils }

    },
}
</script>


<style scoped>
  .pink {
    background-color: rgb(209, 195, 189);
  }
  .green {
    background-color: rgb(157, 212, 210);
  }
.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}

     
      .black {
        color: black;
      }

</style>

