import { PmsAccess, } from '@/classes/DbAccess/PmsAccess.js';

export class BookingForm extends PmsAccess {
  constructor(pms_response) {
    super(pms_response);
    
    // body to post
    this.body = {};

  }

  /*
    spec = { requred: yes, type: , }
              type: "string", minlength:3, maxlength:20
              type: "number" meet: "nozero_minus" / "nozero_plus" / "zero_plus" / "zero_minus"onzero nonnegative" / "nonnegative"
              type: "one_in_array", array: []
  
  */
 
  async add_booking(data) {   // data={guest, roomtype, indate, outdate, ......}
    await this.update_pms('Bookings', 'add', data);
  }
  
  async modify_booking(data) {      // data={dataid (중요),  guest, roomtype, .....}
    await this.update_pms('Bookings', 'modify', data);
  }
  //ok
  async cancel_booking(data) {    // data = dataid to cancel    (STRING)
    await this.update_pms('Bookings', 'cancel', data);
  }
 
  
  
}

export let bookingform = new BookingForm();
