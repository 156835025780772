<template>

<div class="my_title">Payment</div>
<table class="mytable">
        <tr>
          <th class="right_talbe_th">日付</th> <th class="right_talbe_th">区別</th>  <th class="right_talbe_th">方法</th>  <th class="right_talbe_th">金額</th><th class="right_talbe_th">Balance</th>
          <th v-if="edit_mode=='yes'"  class="right_talbe_th">delete</th>
        </tr>

        <tr v-for="(payentry,index2) in current_data.payentry" v-bind:key="index2">
            <td class="right_talbe_td">
              {{ DateUtils.y_m_d_h_m(payentry.mydate) }}
            </td> 

            <td class="right_talbe_td">
              {{ payentry.major }}</td><td class="right_talbe_td">{{ payentry.minor }}
            </td>

            <td class="right_talbe_td money">
              {{ FormatUtils.display_money(payentry.amount) }}
            </td> 
            
            <td class="right_talbe_td money">
              {{ FormatUtils.display_money(calculate_balance(index2)) }}
            </td>
            <td v-if="edit_mode=='yes'"  class="right_talbe_th">
              <base-button class="small green" @click="deleteRecord(payentry.id)">del
              </base-button>
            </td>

        </tr>

  </table>
  
  <hr>
  <div class="same-line">
    <p class="right_table_th">Balance : &nbsp;</p>
    <p class="right_table_td">{{  FormatUtils.display_money(current_data.booking.price) }}</p>
    <p class="right_table_th">&nbsp;-&nbsp;</p>
    <p class="right_table_td">{{  FormatUtils.display_money(sumOfPayments(current_data.payentry))  }}</p>
    <p class="right_table_th">&nbsp;=&nbsp;</p>
    <p class="right_table_td">{{  FormatUtils.display_money((current_data.booking.price - sumOfPayments(current_data.payentry))) }}</p>
  </div>
</template>
<script>
import {DateUtils} from  '@/classes/Utils/DateUtils.js';
import {FormatUtils} from  '@/classes/Utils/FormatUtils.js';
import { accounting } from  '@/classes/Accounting.js'

export default {

    name: 'PaymentHistory',
    props: [ "current_data", "edit_mode" ],
    setup(props, { emit }) {

        async function deleteRecord(id) {
            await accounting.delete_payentry(id); // just update DB
            await accounting.refetch();   // to update accounting data
            emit('close');
        }
  function sumOfPayments(payentry) {
    let total = Number(0);
    payentry.forEach(function (value) {
      total = total + Number(value.amount);
    })
    
    return total;
  }
    function calculate_balance(index2) {
      let total = Number(0);
      for (let i=0; i<=index2; i++) {
        total += props.current_data.payentry[i].amount;
      }
      let balance = props.current_data.booking.price - total;
      return balance;
    } 
    


        return { DateUtils, FormatUtils, deleteRecord, sumOfPayments, calculate_balance, };

    }

};
</script>


<style scoped>
.my_title {
  color: yellow;
  font-weight: bold;
  font-size: 18px;
}

.mytable {
  width: 100%;
  border-collapse: collapse;
  background-color: #001a33;
color: lightgray;
font-size: 18px;
margin: 2px;
}
.right_talbe_th {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: lightgray;
  /*border-right: 4px dotted lightgray;  */
  /*border-right: 4px single lightgray;  */
  padding: 0px 0px 0px 3px;
  background-color: #001a33;
}
.right_talbe_td {
  text-align: left;
  font-weight: 100;
  font-size: 15px;
  color: lightgray;
  padding: 0px 0px 0px 3px;
  background-color: #001a33;
}



.money {
   text-align: right;
   margin-left: 2px;
}
</style>
