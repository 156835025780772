<template>
    
      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
              <h3 v-if="mode=='add'">Adding New Info</h3>
              <h3 v-else-if="mode=='modify'">Modify Info</h3>
              <button
                type="button"
                class="btn-close"
                @click="$emit('close')"
                aria-label="Close modal"
              >
              &nbsp;&nbsp;&nbsp;x
              </button>

            </header>

            <section class="modal-body">

              <div class="grid-container">
                <div class="checkinTop">name</div>
                <div class="checkinBot">
                        <input size="20" type="text" v-model="name" placeholder="Enter name">                      
                </div>

                <div class="checkinTop">memo</div>
                <div class="checkinBot">
                        <textarea 
                          v-model="memo"
                          autocomplete="off" autocorrect="off" autocapitalize="off"
                          data-gramm="false"
                          spellcheck="false"
                          rows="10" 
                          cols="80">
                        </textarea>
                </div>
              </div>

            </section>

            <footer class="modal-footer">
              <div v-if="mode=='add'">
                <button type="button" class="btn-blue" @click="myFormAction('add')">
                  Save New Entry
                </button>
              </div>
              <div v-if="mode=='modify'">
                <button type="button" class="btn-blue" @click="myFormAction('modify')">
                  Update Information
                </button>
              </div>
              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>
            </footer>
          </div>
        </div>
      </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { information } from '@/classes/Informations.js';

export default {
  name: 'BookingForm',
  props: [ 'mode', 'current_data' ], 
  setup(props, { emit }) {

    const current_information = computed(() => information.get_current_information() );

    const name = ref ('');
    const memo = ref ('');

    onMounted(() => {
      if (props.mode!='add') {
          updateInputArgs(props.current_data);
      }
    });

    // set inputArgs to the values passed in
    function updateInputArgs(current_data) {
        name.value    =  current_data.name;
        memo.value =  current_data.memo;
    }

    async function myFormAction() {
      let data;
      switch (props.mode) {
        case 'add': 
          data = {info:current_information.value, name:name.value, memo:memo.value };
          await information.add_info(data); 
          break;
        case 'modify':
          data = {info:current_information.value, id:props.current_data.id, name:name.value, memo:memo.value };
          await information.update_info(data); 
          break;
        case 'delete': 
          await information.delete_info(current_information.value, props.current_data.id);
          break;
      }
      await information.fetch_info(current_information.value);
      emit('close');
    }

    return { name, memo, myFormAction, };

  },

}
</script>

<style scoped>



  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    background-color: rgb(136, 85, 9);
    border-radius: 5px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: white;
    justify-content: space-between; 
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 1px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 5px 0;
  font-size: 18px;
}

.checkinTop {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: -5px;
}
.checkinBot {
  grid-column-start: 3;
  grid-column-end: 5;
  margin-top: -10px;
}

</style>