<template>

<!--
this.$toast.show(`Hey! I'm here`);
this.$toast.success(`Hey! I'm here`);
this.$toast.error(`Hey! I'm here`);
this.$toast.warning(`Hey! I'm here`);
this.$toast.info(`Hey! I'm here`);
setTimeout(this.$toast.clear, 3000)

------------ mapActions --=--------------
       mapActions는 method 에 정의한다
import { mapActions } from 'vuex'
methods: {
  ...mapActions('namespace', ['action1', 'action2'])
}

<template>
  <button @click="action1({value:10})"></button>

</template>

------------ mapGetters --=--------------
       mapGetters는 computed 에 정의한다
import { mapGetters } from 'vuex'
computed: {
  ...mapGetters('namespace', ['value1', 'value2'])
}

<template>
  <div v-if="flag===value1">
      <my-component></my-component>
  </div>
</template>


-------------- props -------------------
 props: {
    name: {
      type: String,
      required: true,
    },
    isFavorites: {
      type: Boolean,
      required: false,
      default: '0',
      validator: function(value) {
        return value ==='1' || value === '0';
      }
    }
}


---------------------   events   -----------
emits: {
  'toggle-favorite': function(id) {
    if (id) {
      return true;
    } else {
      alert('is is missing')
      return false;
    }
  }
}


-------------    중요 중요 중요 중요 중요 
             UI update if something is modified --
예를들어 
data: [{id: 1, field1: 1, field2: 2, field3: 3},
       {id: 2, field1: xxx,,.......}]

now., I change data[0]
const identifiedData = this.data.find(
  (field) => field.id === 2
);
identifiedData.field1='new field1 value'

이리하면 data[2]가 수정되며.. 이와 연관된 모든 UI가 update됨

해볼만한것...  most recent에서 하나를 골라. 수정을 할 경우
       1. db에 해당 record를 update한다
       2. data[해당record]를 update한다.
       3. data를 sort by sdate, stime한다.
       4. 그리하면 data와 연관된 모든 ui가 자동으로 변한다.
       5. 즉, 한개의 record가 update됨에 따라. 400개의 record를
          다시 DB에서 읽어올 필요가 없다.

data[]에 새로운 레코드를 더하는 법
  const newRecord = { id: 3, field1: xxx}          
  this.data.push(newRecord)
  data[]가 update되었으므로.... ui가 update된다.
  즉, most recent의 경우.. 다시 sort하면 된다.

data[]에 새로운 레코드를 삭제하는 법
  data: [{id: 1, field1: 1, field2: 2, field3: 3},
       {id: 2, field1: xxx,,.......}]
  this.data = this.data.filter(eachvalue => eachvalue.id !== 3);
  이것은 data의 레코드 중 id가 3이 아닌것만 filter though함.
  즉, 새로운 data는 id=3인 것을 제외하여 새로 복제됨.
  data[]가 update되었으므로.... ui가 update된다.
  즉, most recent의 경우.. 다시 sort하면 된다.

===================    SLOTS    ==============

base component  (여기서는 base-card로 사용)
  <header>
    <slot name="header"></slot>
  </header>
  <slot name="main"></slot>

calling component

  <base-card>
    <template v-slot:header>
      <h3>{{ funnName }}</h3>
      <base-badge :type="role" :caption="something"></base-badge>
    </template>
  
  </base-card>


=======     dynamic clsses     =====
<div :class="boxASelected ? 'demo active' : 'demo'">
  이것은 boxASelected==true이면 demo active를 선택하고
  아니면 demo를 선택한다.

또는 :class="{demo: true, active: boxASelected}"  
  이것은 demo는 항상 선택, active는 boxASelected=true이면

또는 class="demo"는 항상선택
    :class="{active: 조건}"이렇게 해도 된다.
    즉, static dynamic class동시 사용가능

또는
computed: {
  boxAClasses() {
    return {active: this.boxASelected };
  }
}

:class="boxAClasses"로 하면 알기 쉽다.

=================    using reusable   =================

<template>
  <div id="app">
    <h1><button @click="addToCart({major:'cancel_charge', minor: 'card', amount: 2})"></button></h1>
    <ul>
      <li v-for="(cart,index) in basket" :key="index">{{ cart }}</li>
    </ul>

    <Test2 />
  </div>
</template>

<script>
//import Test from './components/Test.vue';
import Test2 from './components/Test2.vue';
import {useCart} from  './composables/test.js'
import { provide } from 'vue'
export default {
  components: {  /*Test*/ Test2 },
  provide: {
    location: 'North Pole',
    geolocation: {
      longitude: 90,
      latitude: 135
    }
  },  
  setup() {

      //provide from setup
      provide('location', 'North Pole')
      provide('geolocation', { longitude:90, latitude:135} )
      const { basket, addToCart } = useCart()

      addToCart({major:'normal', minor: 'cash', amount: 1})

      return { basket, addToCart }
  },
};

///   test2
<template>
    <h1><button @click="addToCart({major:'normal', minor: 'cash', amount: 2})"></button></h1>
    <ul>
      <li v-for="(cart,index) in basket" :key="index">{{ cart }}</li>
    </ul>
    {{ location }}
    {{ geolocation }}
    {{ userLocation }}
    {{ userGeolocation }}
</template>

<script>
import {useCart} from  '../composables/test.js'
import { inject } from 'vue'

export default {
  name: 'Random',
  inject: ['location', 'geolocation'],
  setup() {
      const { basket, addToCart } = useCart()
      //addToCart({major:'normal', minor: 'card', amount: 1})

      // inject in setup
      const userLocation = inject('location')
      const userGeolocation = inject('geolocation')

      return { basket, addToCart, userLocation, userGeolocation }
  }
}
</script>

test.js
import { ref } from "vue";
let basket = ref([]); // <--- same store, global
export function useCart() {
  const addToCart = (item) => {
    basket.value.push(item);
  };
  return { basket, addToCart };
}
-->

<!--
  firestore db structure
  
  1. collections
      1. userinfo
          dataid: 1   document
                                sub-collection
                                                document
                                
                                
                                kind
                                indate
                                outdate
                                
  collection  id      sub-col     id      document                             
  userinfo    dataid  basicinfo   dataid  {name:   , price:   , memo}
                      extrainfo   dataid  {.....}
                      roomassign  dataid  [{seq: , comp:  , room: }, ]
  payentries  auto    {dataid, mydate, amount, ....}                      
                      
                                
          



-->
<div class="container">
 
 <table width="100%">
   <tr>
     <td>

      <the-login
        v-if="!isLoggedIn"
      >
      </the-login>

      <div 
        v-if="isLoggedIn">

        <the-header>
        </the-header>

        <!-- the-navigation will choose the current active component
             and store in userinfo
        -->
        <the-navigation>
        </the-navigation>

      </div>

     </td>
   </tr>
   <tr>
     <td>
      <main>
        <div v-if="isLoggedIn">
          <component :is="current_component" @close="setToDummyComponent"></component>
        </div>
      </main>
     </td>
   </tr>
  </table>
</div>

<toast-window 
  :messages="toast_messages"
  v-if="toast_window">
  </toast-window>

  <HelloWorld msg="Thank you for using our product. We're glad you're with us."/>

 
</template>



<script>
import TheLogin from '@/components/layouts/TheLogin.vue';
import TheHeader from '@/components/layouts/TheHeader.vue';
import TheNavigation from '@/components/nav/TheNavigation.vue';

import AddBooking from '@/pages/AddDirectBooking/AddBooking.vue';
import SearchBooking from '@/pages/SearchBooking/SearchBooking.vue';
import AssignRoom from '@/pages/AssignRoom/AssignRoom.vue';
import Accounting from '@/pages/Accounting/Accounting.vue';
import Quotation from  '@/pages/Quotation/Quotation.vue';
import Information from  '@/pages/Information/Information.vue';
//import SalesStats from  '@/pages/SalesStats/SalesStats.vue';
import dummyComponent from  '@/pages/dummyComponent/dummyComponent.vue';

import { userinfo } from '@/classes/UserInfo.js';
import { _toast_log } from '@/classes/Utils/useToast.js';
import { computed, watch, ref  } from 'vue';

export default {
  components: { TheLogin, TheHeader, TheNavigation, 
  AddBooking, SearchBooking,
                AssignRoom, 
Accounting,
                Quotation, Information, //SalesStats,
                dummyComponent   },
  setup() {

    const isLoggedIn = computed(() => userinfo.get_isLoggedIn() );
    const current_component = computed(() => userinfo.get_current_component() );
    
    /* for toasting message */
    const toast_messages = ref([]);
    const toast_window = computed(() => {
      if (toast_messages.value.length>=1)
        return true;
      else return false;
    });

    // may not be needed
    function setToDummyComponent() {
      userinfo.set_current_component('dummyComponent');
    }

//function sleep(ms) {
  //return new Promise(resolve => setTimeout(resolve, ms));
//}

//async function delayedGreeting() {
//  this is right one to delay
/*
  function delayedGreeting() {
let sum=0;
let x=0;
  for (x=0; x<100000; x++) {
sum = sum + x;
console.log('sum=' + sum);
if (sum>10000) sum = 0;
 }
}
*/


    watch( _toast_log, () => {
// this is not needed 	setTimeout(() => {  console.log("World!"); }, 2000);

///*
//delayedGreeting();
//delayedGreeting();
//delayedGreeting();
//delayedGreeting();
//delayedGreeting();
//*/


      if ( _toast_log.value ) {
        toast_messages.value.push(_toast_log.value);
        _toast_log.value = false;
        setTimeout(() => {
          toast_messages.value.shift();
        }, 10000);
      }
    });

    return { toast_messages, isLoggedIn, setToDummyComponent, current_component, toast_window };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}

/*  global custom CSS                                      */
.same-line {
  /*padding: 1rem;*/
  padding: 0rem;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

/*form {
  margin: 0.2rem auto;
  max-width: 40rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.2rem;
  background-color: #ffffff;
}
*/

textarea  
{  
   font-family:"Times New Roman", Times, serif;  
   font-size: 18px;   
}

h1 {
  padding: 2px;
  margin: 2px;
  font-size: 25px;
  font-weight: bold;
}
h2 {
  padding: 2px;
  margin: 2px;
  font-size: 23px;
}
h3 {
  padding: 2px;
  margin: 2px;
  font-size: 20px;
}

    td
     {
      padding: 0px;
      margin: 0px;
      font-size: 18px;
      text-align: center;
        /*background-color: rgb(110, 66, 223);*/
        color: black;
    }
    
    th {
      padding: 0px;
      margin: 0px;
      font-size: 18px;
      text-align: center;
        background-color: rgb(189, 189, 190);
        color: black;
    }
/*          .bigtitle {
        background-color: rgb(110, 66, 223);
        color: white;
    }

    .bigtitle2 {
        background-color: rgb(235, 144, 235);
        color: black;
    }
.total {
        color:white;
        background-color: black;
      }

    .money {
      text-align: right;
      padding-right: 8px;
    }
*/

label {
  font-weight: bold;
}

h2 {
  font-size: 24px;
  margin: 0.5rem 0;
}

/*input,
select {
  display: block;
  width: 100%;
  font: inherit;
  margin-top: 0.1rem;
  margin-left:0px;
  margin-right: 0px;
}

select {
  width: auto;
}

input[type='text'] {
  display: inline-block;
  width: 150px;
  margin-left: 1px;
  margin-right: 1px;
}
input[type='number'] {
  display: inline-block;
  width: 80px;
  margin-left: 1px;
  margin-right: 1px;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  font-weight: normal;
}
*/
button {
  font: inherit;
  border: 1px solid #0076bb;
  background-color: #0076bb;
  color: white;
  cursor: pointer;
  padding: 0.2rem 1rem;
  border-radius: 10px;
}

button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}


</style>
