<!--    
    input 
    
        props: myCurrentAccountingData  =
          booking: { dataid:...., indate:....,.... price: ....}
          payentry: [ {id:, mydate:, amount:, major:, minor:, }, {....}, ...]
        
        it calls booking-summary and payment-history

        emits: nothing
-->

<template>

      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
              <h3 v-if="myCurrentAccountingData.booking.kind=='C'" width=100% class="text-center">Accounting - Input Payment (罰金, 返金、損害)</h3>
              <h3 v-else width=100% class="text-center">Accounting - Input Payment (入金、返金)</h3>
              <button
                type="button"
                class="btn-close"
                @click="$emit('close')"
                aria-label="Close modal"
              >
              &nbsp;&nbsp;&nbsp;x
              </button>

            </header>

            <section class="modal-body">

              <div class="grid-container">
                <div class="main_left">
                  <booking-summary
                    :current_data="myCurrentAccountingData.booking">
                  </booking-summary>
                </div>
                <div class="main_right">
                  <payment-history
                    edit_mode="yes"
                    :current_data="myCurrentAccountingData"
                    @close="$emit('close')">
                  </payment-history>
                </div>
              </div>
              
              <h3 style="padding:0px, margin:0px; text-align: left; margin-top:2px">
                      Input Payment
              </h3>

              <table width=100% style="border-collapse: collapse; border: 1px solid black;">
                <tr frame=void>
                  <th>区別</th><th>入金方法</th>
                </tr>
                <tr>
                  <td>
                            <div v-if="myCurrentAccountingData.booking.kind=='C'">
                                <select v-model="inputArgs.major" name="major" id="major" v-on:change='changeOptionForMinor()'>
                                    <option value='noshow_charge'>noshow 罰金</option>
                                    <option value='cancel_charge'>cancel 罰金</option>
                                    <option value='refund'>返金</option>
                                    <option value='LOSS'>損害</option>
                                </select>                    
                            </div>
                            <div v-else>
                                  <select v-model="inputArgs.major" name="major" id="major" v-on:change='changeOptionForMinor()'>
                                    <option value='normal'>入金</option>
                                    <option value='refund'>返金</option>
                                  </select>                    
                            </div>
                  </td>

                  <td>
                              <select v-model="inputArgs.minor">
                                    <option v-for="(myoption, index) in inputArgs.inputPaymentMinorOptionText" v-bind:key="index">{{ myoption }}</option>
                              </select>
                  </td>
                </tr>

                <tr>
                  <th>amount</th><th>staff</th>
                </tr>

                <tr>
                  <td>
                    <input type="number" v-model="inputArgs.amount" placeholder="Enter amount">
                  </td>
                  <td>
                    <input type="text" size="5" v-model="inputArgs.staff" placeholder="Enter Staff Initial">
                  </td>
                </tr>

                <tr>
                  <th colspan=2>note</th>
                </tr>
                <tr>
                  <td colspan=2>
                      <input type="text" size='20' v-model="inputArgs.memo"  placeholder="Enter Memo">
                  </td>
                </tr>
              </table>

            </section>

            <footer class="modal-footer">
              <button type="button" class="btn-blue" @click="myformaction(myCurrentAccountingData.booking.dataid)">
                Save Input Payment
              </button>
              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>
            </footer>
          </div>
        </div>
      </div>

</template>

<script>
import { ref, computed } from 'vue';
import {DateUtils} from  '@/classes/Utils/DateUtils.js';
import { accounting } from  '@/classes/Accounting.js'

export default {
    name: 'InputPaymentDialog',
    components: {  },
    setup(_, { emit }) {

        const myCurrentAccountingData = computed(() => accounting.get_current_data() );
        const inputArgs = ref( 
          { major: null, minor: null, amount: null, 
            staff:null, memo:null,
            inputPaymentMinorOptionText: [],
            inputPaymentMinorOptionValue: [],
          } 
        );

        function changeOptionForMinor() {
            //this.amount = this.mydata.balance;
            switch (inputArgs.value.major) {
                // payment의 경우임.
                case 'normal':
                    inputArgs.value.inputPaymentMinorOptionText = ['cash', 'card', 'gmo', 'meta', 'bank', 'prepay', 'point'];
                    inputArgs.value.inputPaymentMinorOptionValue = ['cash', 'card', 'gmo', 'meta', 'bank', 'prepay', 'point'];
                    break;
                case 'refund':
                    inputArgs.value.inputPaymentMinorOptionText = ['cash', 'card', 'gmo', 'meta', 'bank'];
                    inputArgs.value.inputPaymentMinorOptionValue = ['cash', 'card', 'gmo', 'meta', 'bank'];
                    break;
                case 'noshow_charge':
                case 'cancel_charge':
                    inputArgs.value.inputPaymentMinorOptionText = ['cash', 'card', 'gmo', 'meta', 'bank'];
                    inputArgs.value.inputPaymentMinorOptionValue = ['cash', 'card', 'gmo', 'meta', 'bank'];
                    break;
                case 'LOSS':
                    inputArgs.value.inputPaymentMinorOptionText = ['no_noshow_charge', 'no_cancel_charge', 'noshow_badcard', 'cancel_badcard'];
                    inputArgs.value.inputPaymentMinorOptionValue = ['노쇼페날티대상아님', '캔설페날티대상아님', '노쇼불량카드', '캔설불량카드'];
                    break;
            }
        }
        // form actions
        
        function myformaction(dataid) {
            if (!validateentry()) return false;
            submitform(dataid);
        }

        function validateentry() {
          if (inputArgs.value.major==null) {alert('항목 선택하세요. try again.'); return false;}
          else if (inputArgs.value.minor==null) {alert('지불방법을 선택하세요. try again.'); return false;}
          else if (inputArgs.value.amount==0) {alert('금액을 입력하지 않았습니다. try again.'); return false; }
          else if (inputArgs.value.major=='LOSS' && inputArgs.value.amount<=0) {alert('손비처리금액은 플러스금액입력하여야 함. try again.'); return false; }
          else if (inputArgs.value.major=='refund' && inputArgs.value.amount>=0) {alert('환불의 경우 금액은 마이너스 입력하여야함. try again.'); return false; }
          else if (inputArgs.value.staff==null) {alert('Please, enter Staff Initial. try again.'); return false; }
          return true;
        }

        async function submitform(dataid) {
          //console.log('inputpayment::dataid='+dataid)
            var newentry = { 
                dataid: dataid,
                major: inputArgs.value.major, minor: inputArgs.value.minor, memo: inputArgs.value.memo,
                amount: inputArgs.value.amount, staff: inputArgs.value.staff, 
                mydate: DateUtils.offsetedDay(0, "yyyymmddhhmm"),
                deletemark: '0',
            };

            await accounting.add_payentry(newentry); // just update DB
            await accounting.refetch();

            emit('close');
        }

        return { inputArgs, changeOptionForMinor, myformaction, myCurrentAccountingData, }
    },
};
</script>

<style scoped>


td, th {
  font-size: 18px;
  text-align: left;
  margin: 5px;
  padding: 2px;
}


.myleft {
  text-align: left;
  padding-left: 10px;
}



  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 700px;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    /*background-color: green;*/
    background-color: white;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: black;
    font-weight: bold;
    justify-content: space-between;    
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 0px;
  background-color: #001a33;
  padding: 0px;
}
.grid-container > div {
  background-color: #001a33;
  text-align: left;
  padding: 10px;
  font-size: 18px;
  color: white;
}
.main_left {  grid-column: 1 / span 1; }
.main_right {  grid-column: 2 / span 1; }


</style>