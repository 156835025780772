import { PmsAccess, } from '@/classes/DbAccess/PmsAccess.js';
import { ref } from 'vue';
// global
class RoomAssign extends PmsAccess {
  constructor(pms_response) {
    super(pms_response);
    this.roomnames = ref([]);
    this.noRooms = 0;
    this.onedayBookings = ref([]);
    this.sevendayBookings = [];
    this.roomdatecells = ref([]);

    this.refetch_condition = {};
    this.myRoomAssignRoomColor = ["peachpuff", "gold", "springgreen", "thistle", "violet",
      "yellowgreen", "yellow", "tomato", "turquoise", "silver",
      "palegreen", "orange", "wheat", "lightpink", "steelblue",
      "pink", "olive", "tan", "peru", "salmon",
      "plum", "lightskyblue", "khaki", "indianred"];
  }

  get_onedayBookings() { return this.onedayBookings.value; }
  get_roomnames() { return this.roomnames.value; }
  get_roomdatecells() { return this.roomdatecells.value; }

  //ok  // App.vue needs an update for this.
  async fetch_roomassigns(left_date, right_date_array) {
    let data = {
      left: left_date,
      right: right_date_array
    };
    await this.fetch_pms('RoomAssigns', 'get', data);
    if (this.pms_response.type == 'success') {
      this.process_roomassign_info(this.pms_response.response);
    }
  }
  
  async update_room_assign(data) {    // data={  }
    await this.update_pms('RoomAssigns', "update", data);
  }

  // added....  not tested
  async delete_room_assign(data) {    // data=dataid
    await this.update_pms('RoomAssigns', "delete", data);
  }
  
  process_roomassign_info(res) {

    this.roomnames.value = res.roomnames;
    this.noRooms = this.roomnames.value.length;
    this.onedayBookings.value = res.onedayBookings;
    this.sevendayBookings = res.sevendayBookings;
    //console.log(this.roomnames.value)
    //console.log(this.noRooms)
    //console.log(this.roomnames.value)

    var i, j;

    let roomdatecells = new Array(this.noRooms);

    // defining seven days as the col
    for (i = 0; i < this.noRooms; i++)
      roomdatecells[i] = new Array(7);

    // now fill up with intializer
    for (i = 0; i < this.noRooms; i++) {
      
      for (j = 0; j < 7; j++) {
        roomdatecells[i][j] =
        {
          DATAID: "", seq: "", name: this.roomnames.value[i], guestname: "12ss",
          hidden: "hidden", completed: "", background: "white", balance: 0
        };
      }
    }

    // Now, fill up with actual room assign data
    // room: "i102/i102,i103/i103", guestname: "xx", dataid:"xxxxx", seq: '0', dateindex: "0/1/2/3"
    var myrow, mycol;
    var mycolorindex = 0;
      

    for (i = 0; i < this.sevendayBookings.length; i++) {
      //for (i=0; i<payload.res.sevendayBookings.length; i++) {

      mycolorindex = i % 24;

      let myothersize = this.sevendayBookings[i].roomrow.length;
      //let myothersize = payload.res.sevendayBookings[i].roomrow.length;
        
      for (j = 0; j < myothersize; j++) {

        myrow = this.sevendayBookings[i].roomrow[j][0];
        mycol = this.sevendayBookings[i].roomrow[j][1];
        //myrow = payload.res.sevendayBookings[i].roomrow[j][0];
        //mycol = payload.res.sevendayBookings[i].roomrow[j][1];

        if ((myrow >= 0 && myrow < this.noRooms) && (myrow >= 0 && mycol < 7)) {

          roomdatecells[myrow][mycol].dataid = this.sevendayBookings[i].dataid;
          roomdatecells[myrow][mycol].nights = this.sevendayBookings[i].nights;
          roomdatecells[myrow][mycol].indate = this.sevendayBookings[i].indate;
          roomdatecells[myrow][mycol].seq = this.sevendayBookings[i].seq;
          roomdatecells[myrow][mycol].dayIndexToAssign = this.sevendayBookings[i].dayIndexToAssign;
          // console.log(res.sevendayBookings[i].indate)
          roomdatecells[myrow][mycol].guestname = this.sevendayBookings[i].guestname;
          roomdatecells[myrow][mycol].completed = this.sevendayBookings[i].completed;
roomdatecells[myrow][mycol].balance = this.sevendayBookings[i].balance;

          roomdatecells[myrow][mycol].background = this.myRoomAssignRoomColor[mycolorindex];
          roomdatecells[myrow][mycol].hidden = "display";

        }
      }
    }
    this.roomdatecells.value = roomdatecells;
  }
}

export let roomassign = new RoomAssign()
