<template>
<div class="grid-container"  style="background-color:#aaa;">
  
  <!--
            date change and fetch data accordingly 
  -->
  <div class="header">
    <fetch-data @date-selected="dateSelected"/>
  </div>
  <div class="left" style="background-color:#aaa;">
        <div class="BoxRegion">
          <h2 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">Booking List</h2>

          <!-- left side  ::    booking list -->
          <div class="grid-container2">

                <!--
                    list daily regular bookings
                    when "ACT" of a booking is clicked,
                    the 'day-booking' will open 'action-menu.vue'
                -->

                <!--<list-booking v-else-if="dataAvailable"</list-booking>-->
                <div v-if="isLoading">
                  <base-spinner></base-spinner>
                </div>

                <div v-else-if="dataSelectedFlag && dataAvailable">
                  <list-booking 
                    selection="regular">
                  </list-booking>
                  <list-booking
                    selection="cancel">
                  </list-booking>
                </div>                
          </div>
        </div>
  </div>

  <div class="right" style="background-color:#aaa;">
        <div class="BoxRegion">
<!--          <h2 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">Big Accounting</h2>-->
          <div class="grid-container2">
            <div v-if="!isLoading && dataSelectedFlag && dataAvailable ">

              <h2 style="padding:0px; margin: 0px;">Payment (Regular Bookings)
                &nbsp;&nbsp;&nbsp;&nbsp;<button @click="print_daily_accounting">Print Accounting</button>
              </h2>
              <day-account
                select="regular">
              </day-account>

              <h2 style="padding:0px; margin: 0px;">Payment (Canceled Bookings)</h2>
              <day-account
                select="cancel">
              </day-account>

              <h2 style="padding:0px; margin: 0px;">Small Accounting</h2>
              <small-account>
              </small-account>

            </div>
          </div>
        </div>

        <div class="BoxRegion">
          <h2 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">Small New-Entry & Money-Counting</h2>
          <!-- right side  ::    small accounting  -->
          <div class="grid-container2">
              <new-small-entry
                v-if="!isLoading && dataSelectedFlag && dataAvailable && open_new_entry_form"
                @close="open_new_entry_form=false">
              </new-small-entry>

              <money-counting 
                v-if="!isLoading && dataSelectedFlag && dataAvailable && open_moneycountnig_form"
                :oldMoneyCounting="money_counting[0]"
                :myAccountDate="myAccountDate"
                @close="open_moneycountnig_form=false">
              </money-counting>

              <div v-if="!isLoading && dataSelectedFlag && dataAvailable">
                <p style="color: black; padding:0px; margin:6px 0px 0px 0px;">
                  <button class="btn btn-primary" @click="openSmallForms('small-entry')">New Entry</button>
                  &nbsp;&nbsp;&nbsp;&nbsp;<button class="btn btn-primary" @click="openSmallForms('money-counting')">Money Counting&nbsp;&nbsp;&nbsp;{{ money_counting[0].total }}</button>&nbsp;&nbsp;
                </p>
                <p style="color: black; padding:0px; margin:0px;">FINAL CASH = cash (booking) + cash (canceled booking) + Etc Cash in - Etc Cash out</p>
                <p style="color: black; padding:0px; margin:0px;">
                  {{ FormatUtils.display_money(stats_normal_payentries.cash) }} + {{ FormatUtils.display_money(stats_cancel_payentries.cash) }} + 
                  {{ FormatUtils.display_money(stats_small_entries.cashin) }} - {{ FormatUtils.display_money(stats_small_entries.cashout) }} = 
                  {{ FormatUtils.display_money(stats_normal_payentries.cash + stats_cancel_payentries.cash + stats_small_entries.cashin - stats_small_entries.cashout) }}
                </p>
              </div>
          </div>
        </div>        
        
  </div>
</div>


</template>

<script>
  import FetchData from "@/pages/Accounting/FetchData.vue";

  import ListBooking from "@/pages/Accounting/DayBooking/ListBooking.vue";

  import DayAccount from "@/pages/Accounting/DayAccount/DayAccount.vue";

  import SmallAccount from "@/pages/Accounting/SmallAccount/SmallAccount.vue";
  import NewSmallEntry from '@/pages/Accounting/SmallAccount/NewSmallEntry.vue';
  import MoneyCounting from '@/pages/Accounting/SmallAccount/MoneyCounting.vue';

  import {ActionMenuUtils} from  '@/classes/Utils/ActionMenuUtils.js';
  import {DateUtils} from '@/classes/Utils/DateUtils.js';
  import {FormatUtils} from '@/classes/Utils/FormatUtils.js';
  import { accounting } from '@/classes/Accounting.js';

  import { ref, computed, onMounted } from 'vue';

	export default {
    components: { FetchData,

                  ListBooking, 

                  DayAccount, 

                  SmallAccount, NewSmallEntry, MoneyCounting  
      },
    props: [],

    setup() {
      const my_dateoffset = ref(0);
      const myAccountDate = ref('');
      const isLoading = ref(false);
      const dataSelectedFlag = ref(false);
      const open_new_entry_form = ref(false);
      const open_moneycountnig_form = ref(false);

      // data providing
      //const regular_bookings = computed(() => { return store.getters['regular_bookings']; });
      //const cancel_bookings = computed(() => { return store.getters['cancel_bookings']; });
      //const small_entries = computed(() => { return store.getters['accounting/small_entries']; } );

      const stats_normal_payentries = computed(() => { return accounting.get_stats_normal_payentries() } );
      const stats_cancel_payentries = computed(() => { return accounting.get_stats_cancel_payentries() } );
      const stats_small_entries = computed(() => { return accounting.get_stats_small_entries() } );
      const money_counting = computed(() => { return accounting.get_money_counting()  } );
      const dataAvailable = computed(() => {
        return !isLoading.value && accounting.get_money_counting()!=undefined && accounting.get_money_counting().length==1;
      });

      function dateSelected(dateoffset) {
        dataSelectedFlag.value = true;

        if (my_dateoffset.value!=dateoffset) {
          my_dateoffset.value = dateoffset;
          myAccountDate.value = DateUtils.offsetedDay(dateoffset, 'yyyymmdd');
          accounting.fetch_data(myAccountDate.value);
        }
      }

      onMounted(() => { 
          my_dateoffset.value = 0;
          myAccountDate.value = DateUtils.offsetedDay(my_dateoffset.value, 'yyyymmdd');
          accounting.fetch_data(myAccountDate.value);
      });

      function openSmallForms(whichDialog) {
        
        if ( DateUtils.offsetedDay(0, 'yyyymmdd') != myAccountDate.value ) {
          alert('New Entry or money counting is possible only for today.');
          return false;
        }
        switch (whichDialog) {
          case 'small-entry':
            open_new_entry_form.value = true;
            break;
          case 'money-counting':
            open_moneycountnig_form.value = true;
            break;
        }
      }

      function print_daily_accounting() {
        ActionMenuUtils.print_daily_accounting(myAccountDate.value);
      }


      return  { print_daily_accounting, FormatUtils,
                openSmallForms, dataSelectedFlag,
                stats_normal_payentries, stats_cancel_payentries,
                stats_small_entries, money_counting, dateSelected, dataAvailable,
                myAccountDate, open_new_entry_form, open_moneycountnig_form,
                 }
    },
};
</script>

<style scoped>

.BoxRegion {
  box-sizing: content-box;  
  padding: 2px 10px 10px 10px;
  margin: 3px 2px 3px 2px;

  /*padding: 1px;  */
  border: 1px solid red;
  background-color: white;
  color: black;
  border-radius: 20px;
}

.header {
  grid-area: header;
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  font-size: 22px;
}

/* The grid container */
.grid-container {
  display: grid;
  grid-template-areas: 
    'header header' 
    'left right';
  /* grid-column-gap: 10px; - if you want gap between the columns */
} 

.left,
.right {
  padding: 3px;
  height: auto; /* Should be removed. Only for demonstration */
}

/* Style the left column */
.left {
  grid-area: left;
}

/* Style the right column */
.right {
  grid-area: right;
}

.grid-container2 {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
}

.grid-container2 > div {
  background-color: /*rgba(255, 255, 255, 0.8)*/ white;
  text-align: center;
  padding: 0px 0;
  font-size: 22px;
}

</style>

