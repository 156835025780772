<template>

      <h2 style="text-align: left;margin-top: 0px; margin-bottom: 0px; background-color: white;">Details&nbsp;&nbsp;&nbsp;&nbsp;
            <base-button @click="edit_handler" mode="purple medium">EDIT</base-button>&nbsp;&nbsp;&nbsp;&nbsp;
            <base-button @click="delete_handler" mode="purple medium">DELETE</base-button>
      </h2>

              <div class="grid-container">
                <div class="inputFieldTop1">name</div>
                <div class="inputFieldBot1 boxDiv">
                  {{ current_data.name }}
                </div>

                <div class="inputFieldTop1">memo</div>
                <div class="inputFieldBot1 mySpanStyle boxDiv">
                  {{ current_data.memo }}
                </div>
              </div>
  <info-form
    v-if="open_info_form"
    :mode="form_mode"
    :current_data="current_data"
    @close="open_info_form=false;"
  >
  </info-form>

</template>

<script>
import { information } from '@/classes/Informations.js';
import { ref, computed } from 'vue';

export default {
  name: 'BookingForm',
  setup() {

      const current_data = computed(() => information.get_current_data() );
      const current_information = computed(() => information.get_current_information() );

      const open_info_form = ref(false);
      const form_mode = ref(false);

      function edit_handler() {
        //console.log('viewdetail Edit Button clicked')
        form_mode.value = 'modify';
        open_info_form.value = true;
      }

      async function delete_handler() {
        await information.delete_info(current_information.value, current_data.value.id);
        await information.fetch_info(current_information.value);
      }

    return { delete_handler, edit_handler, open_info_form, current_data, form_mode };

  },

}
</script>

<style scoped>

th {
  background-color: skyblue;
  color: black;
}
.boxDiv {
  background-color: lightgrey;
 width: 100%;
  border: 1px solid green;
  padding: 5px;
  margin: 5px;
}
.mySpanStyle {
  color: black;
  white-space: pre;
  font-weight: normal;  
}
.grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 1px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 5px 0;
  font-size: 18px;
}

.inputFieldTop1 {
  grid-column-start: 1;
  grid-column-end: 2;
  /*/margin-bottom: 0px;*/
  font-weight: bold;
  padding: 4px;
  /*margin-top: 0px;*/
}

/*  bottom */
.inputFieldBot1 {
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 4px;
  margin-top: -4px;
}


</style>