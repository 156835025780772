<template>
                <h3 class="myh3">Quotation Date: {{ myOtherInfo.today }}</h3>
                <p>{{ myOtherInfo.validity }}</p>
                <h5 class="myh5">{{ myOtherInfo.roomDescription }}</h5>
                <h5 class="myh5">{{ myOtherInfo.roomcapacity }}</h5>
                <h5 class="myh5">{{ myOtherInfo.petParkingExtra }}</h5>
                <h5 class="myh5">no of guests = {{ myQuotation.nguests }}</h5>

                <table class="borderedtable">
                  <tr height="20px" class="bigtitle">
                      <td>Date</td>
                      <td>Day</td>
                      <td>Price Level</td>
                      <td>Price</td>
                  </tr>
                    <tr v-for="(value, index) in myQuotation.dailyprice" v-bind:key="index">
                      <td>{{ value.date }}</td>
                      <td>{{ value.dayOfWeek }}</td>
                      <td>{{ value.rank }}</td>
                      <td class="money">{{ value.dateprice }}</td>
                    </tr>
                    <tr class="bigtitle">
                      <td></td>
                      <td></td>
                      <td>Price:</td>
                      <td class="money">{{ myQuotation.total }}</td>
                    </tr>
                    <tr class="bigtitle">
                      <td>Discount Rate:</td>
                      <td>{{ myQuotation.discount }}%</td>
                      <td>Discounted Price:</td>
                      <td class="money">{{ myQuotation.disTotal }}</td>
                    </tr>
                </table>
</template>
<script>
export default {
    name: 'displayQuotation',
    props: ['myQuotation', 'myOtherInfo' ],
    setup() {
      return {  };
    },
};
</script>
     
<style scoped>

.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}

.myh3{
  font-weight: bold;
  font-size: 18px;
  margin: 2px;
  padding: 2px;
}
.myh5{
  font-size: 18px;
  margin: 2px;
  padding: 2px;
}
.myp{
  font-size: 16px;
  margin: 2px;
  padding: 2px;
}

</style>

