<template>
 <div>
  <!-- 
      in select-search.vue, most recent, searcybyname, or searchbydate is selected.
      for the selected search method, 
      if search is OK, then list-booking is opened.
  -->
  <select-search
    @set-search="set_search_handler">
  </select-search>
  
  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  
  <!-- 
      list-booking.vue lists the bookings
  -->
  <list-booking
    v-else-if="is_data_available"
    :search_result_title="search_result_title"
  >
  </list-booking>
  </div>
</template>

<script>
import SelectSearch from './SelectSearch.vue';
import ListBooking from './ListBooking.vue';
import { ref, onUnmounted, computed } from 'vue';
import { searchbooking}  from '@/classes/SearchBooking.js';
import BaseSpinner from '@/components/ui/BaseSpinner.vue';
//import { getCurrentInstance } from 'vue';

export default {
  components: {     SelectSearch,      ListBooking,     BaseSpinner,   },

  setup() {

    const timer = ref('');
    const search_result_title = ref('');
    const isLoading = ref(false);
    const search_by = ref("");
    const search_target = ref("");

    const is_data_available = computed(() => {
      return !isLoading.value && searchbooking.get_data().length>=1;
    });

    /*
            search_spec = { method: ...., target: .xxxxx }
    */
    function set_search_handler(search_spec) {
      
      search_by.value = search_spec.search_by;
      search_target.value = search_spec.search_target;
      //console.log('by='+search_by.value)
      //console.log('target='+search_target.value)

      // set page title for list-booking component
      search_result_title.value = searchbooking.set_search_result_title(search_by.value);

      // defining 'fetch_data' is to repeat calling it by "setInterval"
      fetch_data();

      clearInterval(timer.value);
      if (search_by.value=='mostrecent') {
        timer.value = setInterval(
          fetch_data, 
          60000);
      }
    }
    
    // defining 'fetch_data' is to repeat calling it by "setInterval"
    function fetch_data() {
      //console.log('by='+search_by.value)
      //console.log('target='+search_target.value)
      searchbooking.fetch_booking(search_by.value, search_target.value);
    }

    onUnmounted(() => { 
      clearInterval(timer.value);
      //console.log('onUnmounted from search booking')
      searchbooking.reset_data();
    });


    return { set_search_handler, is_data_available, isLoading, search_result_title,  };

  },

};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
</style>