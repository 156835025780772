<template>
      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">

              <slot name="header">
                default title
              </slot>

              <button
                  type="button"
                  class="btn-close"
                  @click="$emit('close')"
                  aria-label="Close modal"
              >&nbsp;&nbsp;&nbsp;x
              </button>

            </header>

            <section class="modal-body">

              <div class="grid-container">
                <div class="main_left">
                  <booking-summary
                    :current_data="myCurrentAccountingData.booking">
                  </booking-summary>
                </div>
                <div class="main_right">
                  <payment-history
                    edit_mode="no"
                    :current_data="myCurrentAccountingData"
                    @close="$emit('close')">
                  </payment-history>
                </div>
              </div>

              <hr>
              
              <div class="grid-container-input">
                <div class="line1_item">
                  <div class="inside-container">
                    <div class="inside-div-label">checkin
                    </div>
                    <div class="inside-div-input">
                      <select v-model="myInputField">
                          <option disabled value="">select</option>
                          <option v-for="(value,index) in mySelectOptions" 
                            :key="index"
                            :value="value">{{ value }}</option>
                      </select>
                    </div>
                  </div>
                </div>  
              </div>            

            </section>

            <footer class="modal-footer">

              <slot name="footer">
              </slot>
              <button type="button" class="btn-green" @click="myFormAction" aria-label="Close modal">
                Save Info
              </button>

              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>

            </footer>
          </div>
        </div>
      </div>
</template>

<script>
import { ref, computed } from 'vue';
import { accounting } from  '@/classes/Accounting.js';

export default {
  name: 'BookingForm',
  props: [ 'updateWhat', 'inputFieldTitle', /*'myCurrentAccountingData' */],
  setup(props, { emit }) {

    const myCurrentAccountingData = computed(() => accounting.get_current_data() );

    const myInputField = ref('');

    const inputOptions = ref (
      {
        checkinOptions  : [ 'yes', 'no' ],
        markingOptions:   [ 'remove', 'user', 'tax', ],
        agentOptions : [ 'exten', 'visit', 'phone', 'minda', 'other']
      });

    const mySelectOptions = computed(() => {
      switch (props.updateWhat) {
        case 'update checkin status':
          return inputOptions.value.checkinOptions;
      }
      return null;
    });

    async function myFormAction() {

      if (!verifyInput()) return false;
      
      let dataid = myCurrentAccountingData.value.booking.dataid;
      let value = myInputField.value;
      switch (props.updateWhat) {
        case 'update checkin status':
            await accounting.modify_checkin_status({dataid, newstatus:value}); // just update DB
            await accounting.refetch();   // to update accounting data
            break;
      }
      emit('close');
    }

    function verifyInput() {
      switch (props.updateWhat) {
        case 'update checkin status':
          if ( inputOptions.value.checkinOptions.indexOf(myInputField.value) === -1 ) {
            //toastErrorMessages( [ 'invalid roomtype. try again.' ] );
            alert ('invalid data try again');
            return false;
          }
          break;
      }
      return true;
    }

    return { myInputField, mySelectOptions, myFormAction, myCurrentAccountingData };

  },

}
</script>

<style scoped>

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 650px;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    /*background-color: green;*/
    background-color: white;
    display: flex;
    border-radius: 5px;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: black;
    font-weight: bold;
    justify-content: space-between;    
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    border-radius: 5px;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 0px;
  background-color: #001a33;
  padding: 0px;
}
.grid-container > div {
  background-color: #001a33;
  text-align: left;
  padding: 10px;
  font-size: 18px;
  color: white;
}
.main_left {  grid-column: 1 / span 1; }
.main_right {  grid-column: 2 / span 1; }


.grid-container-input {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
}
.grid-container-input > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 0;
  font-size: 30px;
}

.inside-container {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px;
  background-color: white;
  padding: 0px;
  margin: 0px 0px 0px 0px;
}
.inside-div-label {
  background-color: white;
  text-align: left;
  padding: 0; margin: 5px 1px 0px 1px;
  font-size: 15px;
  font-weight: bold;
}
.inside-div-input {
  background-color: white;
  text-align: left;
  padding: 0; margin: -1px 1px 0px 1px;
  font-size: 20px;
}
.line1_item {
  grid-column: 1 / span 1;
}


</style>