<template>
      <div class="fade-in">
        <div class="modal-backdrop">
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
              <h3 width=100% class="text-center">Adding New Small-entry</h3>
              <button
                type="button"
                class="btn-close"
                @click="$emit('close')"
                aria-label="Close modal"
              >
              &nbsp;&nbsp;&nbsp;x
              </button>

            </header>


            <section class="modal-body">

              <div class="grid-container">

                <div class="inputFieldTop">Selec Income or Outcome</div>
                <div class="inputFieldBot">
                      <select id="kind" v-model="inputArgs.kind">
                        <option disabled value="">Choose Income or Outcome</option>
                        <option value="income">Income</option>
                        <option value="outcome">Outcome</option>
                      </select>                    
                </div>

                <div class="inputFieldTop">Choose One</div>
                <div class="inputFieldBot">
                    <select 
                        v-if="inputArgs.kind=='income'"
                        v-model="inputArgs.what">
                         <option disabled value="">choose one</option>
                         <option value='refill'>money refill</option>
                         <option value='pickup'>pickup</option>
                         <option value='roomupgrade'>roomupgrade</option>
                         <option value='extraperson'>extraperson</option>
                         <option value='early-in'>early-in</option>
                         <option value='late-out'>late-out</option>
                         <option value='breakfast'>breakfast</option>
                         <option value='pocketwifi'>pocketwifi</option>
                         <option value='bike'>bike</option>
                         <option value='luggage'>luggage</option>
                         <option value='towel'>towel</option>
                         <option value='toothbrush'>toothbrush</option>
                         <option value='bath ticket'>bath ticket</option>
                         <option value='宅配便'>宅配便</option>
                         <option value='ticket service'>ticket service</option>
                         <option value='adaptor'>adaptor</option>
                         <option value='print'>print</option>
                         <option value='laundry'>laundry</option>
                         <option value='vending'>vending</option>
                        <option value='coffee'>coffee</option>
                        <option value='etc-income'>etc-income</option>
                    </select>
                    <select 
                        v-else
                        v-model="inputArgs.what">
                        <option disabled value="">choose one</option>
                        <option value='towel wash'>towel wash</option>
                        <option value='sacho take'>sacho take</option>
                        <option value='buying'>buying</option>
                        <option value='bill'>bill</option>
                        <option value='refund'>refund</option>
                        <option value='etc-outcome'>etc-outcome</option>
                    </select>
                </div>

                <div class="inputFieldTop">Amount</div>
                <div class="inputFieldBot">
                    <input 
                        v-if="inputArgs.kind==='income'"
                        type="number" v-model="inputArgs.income"
                        placeholder="Enter amount">

                    <input
                        v-else
                        type="number" 
                        v-model="inputArgs.outcome"
                        placeholder="Enter amount">
                </div>

                <div class="inputFieldTop">入金方法</div>
                <div class="inputFieldBot">
                      <select v-model="inputArgs.method">
                          <option disabled value="">Choose One</option>
                          <option value='cash'>cash</option>
                          <option value='card'>card</option>
                          <option value='gmo'>gmo</option>
                          <option value='meta'>meta</option>
                          <option value='bank'>bank</option>
                      </select>
                </div>

                <div class="inputFieldTop">note</div>
                <div class="inputFieldBot">
                      <input type="text" v-model="inputArgs.note" placeholder="Enter note">
                </div>

                <div class="inputFieldTop">staff</div>
                <div class="inputFieldBot">
                      <input type="text" v-model="inputArgs.staff" placeholder="Enter staff initial">
                </div>

              </div>

            </section>

            <footer class="modal-footer">

              <button type="button" class="btn-blue" @click="myformaction">
                Save New Entry
              </button>
              <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
                Close me!
              </button>

            </footer>

          </div>
        </div>
      </div>
</template>

<script>
import { ref, computed } from 'vue';
import { accounting } from  '@/classes/Accounting.js';
import {DateUtils} from '@/classes/Utils/DateUtils.js';

export default {
  emits: [],
  components: {},
  props: [ ],
  setup(_, { emit }) {

    const account_date = computed(() => accounting.get_account_date() );

    const inputArgs = ref(
      {
        kind: 'income',
        what: '',
        income: 0,
        outcome: 0,
        note: '',
        staff: '',
        method: '',
        whatOptions: [ 'refill', 'pickup', 'roomupgrade', 'extraperson', 'early-in', 
                        'late-out', 'breakfast', 'pocketwifi', 'bike', 'luggage',
                        'towel', 'toothbrush', 'bath ticket', '宅配便', 'ticket service',
                        'adaptor', 'print', 'laundry', 'vending', 'coffee', 'etc-income' ],

      }
    );

    function resetInputArgs() {
      inputArgs.value.kind = 'income';
      inputArgs.value.what = '';
      inputArgs.value.income = 0;
      inputArgs.value.outcome = 0;
      inputArgs.value.note = '';
      inputArgs.value.staff = '';
      inputArgs.value.metho = '';
    }

    async function myformaction() {
      if (!validateentry()) return;
      var myobj = { 
          kind: inputArgs.value.kind,
          what: inputArgs.value.what,
          income: inputArgs.value.income,
          outcome: inputArgs.value.outcome,
          note: inputArgs.value.note,
          staff: inputArgs.value.staff,
          method: inputArgs.value.method,
          mydate: DateUtils.offsetedDay(0, 'yyyymmddhhmm')
      };

      resetInputArgs();
      await accounting.add_small_entry(myobj); // just update DB
      await accounting.fetch_data(account_date.value);
      emit('close');
    }

    function validateentry() {
      if (inputArgs.value.what==0) {alert('select item. try again.'); return false;}
      else if (inputArgs.value.income<0) {alert('only positive amonut. try again.'); return false;}
      else if (inputArgs.value.outcome<0) {alert('only positive amount. try again.'); return false; }
      else if (inputArgs.value.kind=='income' && inputArgs.value.income==0) {alert('enter amount. try again.'); return false; }
      else if (inputArgs.value.kind=='outcome' && inputArgs.value.outcome==0) {alert('enter amount. try again.'); return false; }
      else if (inputArgs.value.staff=="") {alert('enter staff initial. try again.'); return false; }
      else if (inputArgs.value.note=="") {alert('enter memo. try again.'); return false; }
      switch (inputArgs.value.method) {
          case 'cash': 
          case 'card':
          case 'gmo':
          case 'meta':
          case 'bank': break;
          default: alert('select cash / crd / gmo / meta/ bank'); return false
      }

      switch (inputArgs.value.kind) {
          case 'income': 
          case 'outcome': break;
          default: alert('select income or outcome'); return false
      }

      return true;
    }

    return { myformaction, inputArgs };

  },
  
}
</script>

<style scoped>
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    background-color: rgb(136, 85, 9);
    border-radius: 5px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: white;
    justify-content: space-between; 
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 1px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 5px 0;
  font-size: 18px;
}

.inputFieldTop {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: -5px;
}

/*  bottom */
.inputFieldBot {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-top: -10px;
}

</style>