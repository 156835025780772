<template>
<div class="same-line">
    <div>loginid:: &nbsp;&nbsp;&nbsp;  <input id="loginId" v-model="loginid" type="text"></div>
    <div>pass:: &nbsp;&nbsp;&nbsp;<input id="password" type="text" v-model="password"></div>
    <button @click="checkPassword">Login</button>
</div>
</template>

<script>
import { userinfo } from '@/classes/UserInfo.js';
import { ref } from 'vue';

export default {
  
  setup() {

    const loginid = ref('');
    const password = ref('');

    function checkPassword() {
      //userinfo.check_login(loginid.value, password.value);
      userinfo.check_login(loginid.value, password.value);
    }

    return { loginid, password, checkPassword,};
  },
}
</script>

<style scoped>
/*header {
  width: 100%;
  height: 2rem;
  background-color: #640032;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
*/
header h1 {
  color: white;
  margin: 0;
}

input,
select {
  display: block;
  width: 100%;
  font: inherit;
  margin-top: 0.1rem;
}

button {
  font: inherit;
  border: 1px solid #0076bb;
  background-color: #0076bb;
  color: white;
  cursor: pointer;
  padding: 0.75rem 0.1rem;
  border-radius: 10px;
}

button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}


</style>