import { DebugUtils } from '@/classes/Utils/DebugUtils.js';

export class PmsAccess extends DebugUtils {

  constructor(debug_flag) {
    super(debug_flag);
    this.pms_response = {};
  }

  async update_pms(api_to_call, action, data) {
    try {
      const response = await this.pmsDbAccess(api_to_call, action, data);
      this.toast_message( { type: 'success', api_to_call, action, data, response:response });
      this.pms_response = { type: 'success', api_to_call, action, data, response:response };
    } catch (error) {
      this.toast_message( { type: 'error', api_to_call, action, data, response: error.message });
      this.pms_response = { type: 'error', api_to_call, action, data, response: error.message };
    }
}

  async fetch_pms(api_to_call, action, data) {
        try {
          //console.log('pmsaccess.js:: api_to='+api_to_call)
          const response = await this.pmsDbAccess(api_to_call, action, data);
          this.toast_message( { type: 'success', api_to_call, action, data, response:response });
          this.pms_response = { type: 'success', api_to_call, action, data, response:response };
        } catch (error) {
          this.toast_message( { type: 'error', api_to_call, action, data, response: error.message });
          this.pms_response = { type: 'error', api_to_call, action, data, response: error.message };
        }
  }

  /*
      if using router, it will be simpler
      now, just send body info along with the path info
  */
  async pmsDbAccess(api_to_call, action, data) {
    const url = './api/' + api_to_call + '.php';
    //console.log('url = ' + url)
    //console.log(JSON.stringify({action, data}))
    
        const response = await fetch(url, 
          { 
            mode: 'cors',
            method: "POST",
            body: JSON.stringify({action, data})
          });
        const responseData = await response.json();
        //console.log(responseData)
        if (!response.ok) {
            const error = new Error(responseData.message);
            throw error;
        }
        return responseData;
  }
}
