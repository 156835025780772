/*dateutility는 20210131로 통일하고
즉, dateutility를 사용하려면 20210131로 변화후 사용한다.Array
*/

    // 2021 01 03 ==> 21-01-03 (y_m_d)
    // 2021 01 03 ==> 2021-01-03 (Y_m_d)
    
export class DateUtils {
    
    static date_picker_config = 
      {
          locale: {
            format: "YYYY/MM/DD",
            weekday: ["sun", "mon", "tue", "wed", "thr", "fri", "sat"],
            startsWeeks: 0,
            todayBtn: "TODAY",
            clearBtn: "Clear",
            closeBtn: "Close"
          }
      };

    static display_date(value, format) {
        // 18-10-01
        switch (format) {
            case 'y_m_d':
                return value.substr(2,2)+'-'+value.substr(4,2)+'-'+value.substr(6,2);
            case 'Y_m_d':
                return value.substr(0,4)+'-'+value.substr(4,2)+'-'+value.substr(6,2);
            case 'yymmddhi':
                return value.substr(2, 2) + "-" + value.substr(4,2) + "-" + value.substr(6,2) +
                            " " + value.substr(8,2) + ":" + value.substr(10,2);
        }
        return value;
    }
  
	static backColorForDate(mydate) {

        let today = this.offsetedDay(0, 'yyyymmdd');
        let onedayafter = this.offsetedDay(1, 'yyyymmdd');
        let twodayafter = this.offsetedDay(2, 'yyyymmdd');

        if (mydate==today) return 'today';
        if (mydate==onedayafter) return 'onedayafter';
        if (mydate==twodayafter) return 'twodayafter';
        return "";
    }

    static foreColor(KIND) {
        if (KIND=='C') return "red_foreground";
        return "black_foreground";
    }


    // 2021 02 14 22 27 ==> 21-02-14 22:27
    // 0123 45 67 89 01
    static y_m_d_h_m(mydate) {
        return mydate.substr(2, 2) + '-' + mydate.substr(4,2) + '-' + mydate.substr(6,2) + ' ' + mydate.substr(8,2) + ':' + mydate.substr(10, 2)
    }

    static display_date_for_accounting (mydate, country) {
        switch (country) {
            case 'jp': return this.yearName(mydate, country) + ' ' + this.monthName(mydate, country) + ' ' + this.dayName(mydate, country) + " (" + this.dayOfWeek(mydate, country) + ")";
            case 'kr': return this.yearName(mydate, country) + ' ' + this.monthName(mydate, country) + ' ' + this.dayName(mydate, country) + " (" + this.dayOfWeek(mydate, country)+ ")";
            case 'en': return this.monthName(mydate, country) + ' ' + this.dayName(mydate, country) + ', ' + this.yearName(mydate, country)+ " (" + this.dayOfWeek(mydate, country)+ ")";
            default:  return  this.monthName(mydate, country) + ' ' + this.dayName(mydate, country) + ', ' + this.yearName(mydate, country)+ " (" + this.dayOfWeek(mydate, country)+ ")";
        }
    }

    static displayHourMinute(value) {
        var temp;  // 202101012300
        temp = value.substr(8,2) + ':' + value.substr(10,2);
        return temp;
    }

    static dayName(mydate, country) {
        var day = mydate.substr(6, 2);
        day = parseInt(day);
        switch (country) {
            case 'jp': return day + '日'
            case 'kr': return day + '일'
            case 'en': return day
            default:  return  day
        }    
    }

    static yearName(mydate, country) {
        var year = mydate.substr(0, 4);
        year = parseInt(year);
        switch (country) {
            case 'jp': return year + '年'
            case 'kr': return year + '년'
            case 'en': return year
            default:  return  year
        }    
    }

                    
    // 20181002 --> Oct 2
    // 2018-10-02 --> Oct 2
    static monthName(mydate, country) {
        const month_en = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        const month_jp = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
        const month_kr = ["1월", "2월月", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
        var month = mydate.substr(4, 2);
        month = parseInt(month);
        switch (country) {
            case 'jp': return month_jp[month-1]
            case 'kr': return month_kr[month-1]
            case 'en': return month_en[month-1]
            default:  return  month_en[month-1]
        }    
    }


    // 2018-10-02 -- monday
    static dayOfWeek(mydate, country) {
        var year = mydate.substr(0, 4);
        var month = mydate.substr(4, 2);
        var day = mydate.substr(6, 2);
        const myday = new Date(year, month-1, day);
        const weekday_jp = ["日", "月", "火", "水", "木", "金", "土"]
        const weekday_en = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"]
        const weekday_kr = ["일", "월", "화", "수", "목", "금", "토"]

        switch (country) {
            case 'jp': return weekday_jp[myday.getDay()]
            case 'kr': return weekday_kr[myday.getDay()]
            case 'en': return weekday_en[myday.getDay()]
            default:  return weekday_en[myday.getDay()]
        }
    }


    static offsetedDay( dateOffset, myFormat ) {
        var today = new Date(); // 2020. 1. 16
        var targetdate = new Date();
   
        targetdate.setDate(today.getDate() + dateOffset);
    
        var month = this.padzero(targetdate.getMonth() + 1);
        var day = this.padzero(targetdate.getDate());
        var year = this.padzero(targetdate.getFullYear());
        var hour = this.padzero(targetdate.getHours());
        var minute = this.padzero(targetdate.getMinutes());

        switch (myFormat) {
            case 'yyyymmdd': return '' + year + month + day;
            case 'yyyymmddhhmm': return '' + year + month + day + hour + minute;
        }
    }

    static toYyyyMmDd(dayin) {
        return dayin.substr(0,4)+dayin.substr(5,2)+dayin.substr(8,2);
    }

    static toPickerFormat(dayin) {
        return dayin.substr(0,4)+'/'+dayin.substr(4,2)+'/'+dayin.substr(6,2);
    }

    static padzero(value) {
        if (value<=9) return '0'+value;
        return value;
    }

    static diffBetweenDays(date1, date2) {
        //console.log('date1='+date1)
        // 20210102
        // 01234567
        var d1 = new Date(date1.substring(0,4)+'-'+date1.substring(4,6)+'-'+date1.substring(6,8));
        var d2 = new Date(date2.substring(0,4)+'-'+date2.substring(4,6)+'-'+date2.substring(6,8));
        return Math.floor((Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate()) - Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()) ) /(1000 * 60 * 60 * 24));
    }

    // 2021/01/02 pickerFormat
    static diffBetweenPickerDays(date1, date2) {
        //console.log('date1='+date1)
        // 2021/01/02
        // 01234567
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        return Math.floor((Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate()) - Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()) ) /(1000 * 60 * 60 * 24));
    }
}    
    
