export class ActionMenuUtils {

  static printSlip(dataid) {
    //window.open("mydata.com/pms/newprintslip.php?pnum="+dataid, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
    //window.open("http://try.com/Slip.php?pnum="+dataid, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
    window.open("./api/Slip.php?pnum="+dataid, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
  }

  static printReceipt(dataid) {
    //window.open("mydata.com/pms/newprintslip.php?pnum="+dataid, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
    //window.open("http://try.com/Slip.php?pnum="+dataid, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
    window.open("./api/Receipt.php?dataid="+dataid, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
  }

  static viewMemo(dataid) {
      window.open("./api/Memo.php?pnum="+dataid, "", "width=700, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
  }

  static print_daily_accounting(mydate) {
    window.open("./api/PrintDailyAccounting.php?mydate="+mydate, "", "width=1100, height=800,scrollbars=yes,resizable=yes,location=yes,titlebar=no");
  }

}
