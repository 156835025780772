//import { getCurrentInstance } from 'vue';
//const instance = getCurrentInstance();
//const toast = instance.proxy.$toast;
import { _toast_log } from '@/classes/Utils/useToast.js';
export class DebugUtils {
  constructor() {
    this.debug_flag = false;
  }

  toast_message(message) {
    if (message.type == 'success')
      _toast_log.value = { type: 'success', message: 'OK : ' + message.api_to_call + ' : ' + message.action };
    else 
      _toast_log.value = { type: 'error', message: 'Fail in : ' + message.api_to_call + ' : ' + message.action + ' :: ' + message.response };
    
    if (this.debug_flag)
      this.print_debug_message(message );
/*    
    if (message.type == 'success')
      toast.success('OK: in ' + message.request.action + ' (' + message.request.api_to_call + ') ');
    else
      toast.error('Fail: in ' + message.request.action + ' (' + message.request.api_to_call + ') :: ' + message.response);
*/      
  }

  print_debug_message(message) {
    this.print_title(message.type);
    this.print_call_action_data(message);
    this.print_response(message.response);
  }

  print_title(message_type) {
    if (message_type == 'error')
      console.log('--------------  Error Message ------------');
    else 
      console.log('--------------  Success Message ------------');
  }

  print_call_action_data(message) {
    console.log('  call: ' + message.api_to_call + ', action: ' + message.action + ', data: ' + message.data)
  }

  print_response(response) {
    console.log('response=');
    console.log(response);
  }


}
