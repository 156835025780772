import { ref, } from 'vue';
import { PmsAccess } from '@/classes/DbAccess/PmsAccess.js';

// global
class SearchBooking extends PmsAccess {
  constructor(pms_response) {
    super(pms_response);
    
    this.bookings = ref([]);
    this.current_data = ref({});
    
    this.search_by = '';
    this.search_target = '';
  }

  reset_data() { this.bookings.value = []; }
  get_data() { return this.bookings.value; }
  
  set_current_data(index) {
    this.current_data.value = this.bookings.value[index];
  }  
  
  get_current_data() {
    return this.current_data.value;
  }  
  
  async fetch_booking(method, target) {
    this.search_by = method;
    this.search_target = target;
    this.refetch();
  }

  // fetch with saved path and body
  async refetch() {
    switch (this.search_by) {
      case 'mostrecent':     await this.fetch_pms('Bookings', "mostrecent", ""); break;
      case 'byname':         await this.fetch_pms('Bookings', "byname", this.search_target); break;
      case 'bydate':         await this.fetch_pms('Bookings', "bydate", this.search_target); break;
    }
    // now the server response is stored in pms_response
    if (this.pms_response.type=='success')
        this.bookings.value = this.pms_response.response;
  }
  async fetch_most_recent() {
    await this.fetch_pms('Bookings', "mostrecent", "");
  }
  //ok
  async fetch_search_byname(data) {   // data=targetname    (STRING)
    await this.fetch_pms('Bookings', "byname", data);
  }
  //ok
  async fetch_search_bydate(data) {   // data=targetdate   (STRING)
    await this.fetch_pms('Bookings', "bydate", data);
  }
  
  set_search_result_title(search_by) {
    switch (search_by) {
      case 'mostrecent': return 'Most Recent';
      case 'byname': return 'Search By Name';
      case 'bydate': return 'Searcy By Check-in Date';
    }
  }  

  
}

export let searchbooking = new SearchBooking();
