<template>
              <table class='borderedtable' style="margin-top: 0px; padding: 0px;">
                <tr class="bigtitle">
                  <th>時間</th> <th>入金</th> <th>支出</th> <th>BY</th> <th>項目</th> <th>詳細</th> <th>Staff</th>
                </tr>
                <tr v-for="(value, index2) in smallEntries" v-bind:key="index2">
                  <td>{{ DateUtils.displayHourMinute(value.mydate) }}</td>
                  <td class="money">{{ FormatUtils.display_money(value.income) }}</td>
                  <td class="money">{{ FormatUtils.display_money(value.outcome) }}</td>
                  <td>{{ (value.method) }}</td>
                  <td>{{ value.what }}</td>
                  <td>{{ value.note }}</td>
                  <td>{{ value.staff }}</td>
                </tr >
              </table>
              <table class='borderedtable'>
                <tr class="bigtitle2">
                  <th></th>
                  <th>現金(入)</th><th>現金(出)</th><th>card</th><th>gmo</th><th>meta</th><th>bank</th><th>TOTAL</th>
                </tr>
                <tr>
                  <td class="total">TOTAL</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats_small_entries.cashin) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats_small_entries.cashout) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats_small_entries.card) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats_small_entries.gmo) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats_small_entries.meta) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats_small_entries.bank) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(gettotal()) }}</td>
                </tr>
              </table>
</template>
<script>
import {FormatUtils} from '@/classes/Utils/FormatUtils.js';
import {DateUtils} from '@/classes/Utils/DateUtils.js';
import { accounting } from '@/classes/Accounting.js';
import { computed } from 'vue';
//import { jsPDF } from "jspdf";
export default {

    name: 'DailySmallAccounting',

    setup() {

      const smallEntries = computed(() =>  accounting.get_small_entries() );
      const day_normal_payentries = computed(() =>  accounting.get_day_normal_payentries() );
      const stats_small_entries = computed(() => accounting.get_stats_small_entries() );
      const moneyCounting = computed(() => accounting.get_money_counting() );

      function gettotal() {
        var temp = Number(0);
        temp = Number(0) + Number(stats_small_entries.value.cashin) + Number(stats_small_entries.value.cashout) +
               Number(stats_small_entries.value.card) + Number(stats_small_entries.value.gmo) +
               Number(stats_small_entries.value.meta) + Number(stats_small_entries.value.bank);
        return temp;
      }

      return {  FormatUtils, DateUtils, gettotal,
                day_normal_payentries, smallEntries, stats_small_entries, moneyCounting }
    },
};
</script>


<style scoped>
.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}


      .black {
        color: black;
      }
      .red {
        color: red;
      }

    .yes {
        color:white;
        background-color: green;
      }
    .no {
        color: white;
        background-color: red;
      }
</style>
