<template>
              <table class='borderedtable'>
                <tr class="bigtitle2">
                  <th></th><th>予納</th><th>point</th>
                  <th>現金</th><th>card</th><th>gmo</th>
                  <th>meta</th><th>bank</th><th>TOTAL</th>
                </tr>
                <tr>
                  <td class="total">TOTAL</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.prepay) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.point) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.cash) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.card) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.gmo) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.meta) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.bank) }}</td>
                  <td class="money total"> {{ FormatUtils.display_money(stats.total) }}</td>
                </tr>
              </table>
</template>
<script>
import {FormatUtils} from  '@/classes/Utils/FormatUtils.js';
export default {

    props: [ 'stats', ],    // data received from the parent component (myaccounting)
    name: 'DayAccount',
    setup() {

      return { FormatUtils, }

    },
}
</script>


<style scoped>
  .pink {
    background-color: rgb(209, 195, 189);
  }
  .green {
    background-color: rgb(157, 212, 210);
  }
.borderedtable {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
}
.border {
    border: 1px solid black;
    border-collapse: collapse;
}

     
      .black {
        color: black;
      }

</style>

