<template>
          <div class="modal" role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header class="modal-header" id="modalTitle">
              <ul>

              <div v-for="(message, index) in messages" :key="index">
                <li v-if="message.type=='error'" style="margin-left: -14px; padding-right:10px; color: red; text-align: left">
                  {{  message.message  }}
                </li>
                <li v-else style="margin-left: -14px; padding-right:10px; color: white; text-align: left">
                  {{  message.message  }}
                </li>
              </div>
              </ul>
            </header>

          </div>
</template>

<script>
export default {
  props: [ 'messages',  ],
  setup() {
    return { };
  },

}
</script>

<style scoped>
  .modal {
    position: absolute;
    top: 70%;
    left: 5%;
    /*transform: translate(-50%, -50%);*/
    height: auto;
    width: auto;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }
  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    background-color: rgb(7, 119, 163);
    border-radius: 5px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: white;
    justify-content: space-between; 
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }


</style>