<template>
    <div style="overflow-x:auto;">      
      <table>
        <tbody v-for="(each, index) in myInfo" :key="index">
          <tr>
            <!-- if index=current_index border   -->
            <td><span :class="{colored_back: activeIndex==index}" @click="onclick(index)">{{ each.name }}</span></td>
          </tr>
        </tbody>

      </table>
    </div>

</template>

<script>

import { information } from '@/classes/Informations.js';
import { ref, computed, } from 'vue';

export default {
  setup(_, { emit }) {
    const myInfo = computed(() => information.get_information() );
    const activeIndex = ref(-1);

    function onclick(clickedIndex) {
      activeIndex.value = clickedIndex;
      information.set_current_index(clickedIndex);
      information.set_current_data(myInfo.value[clickedIndex]);
      emit('item-clicked');
    }

    return { myInfo, onclick, activeIndex, };

  },
};
</script>

<style scoped>
 
table {
  width: 100%;
  border-collapse: collapse;
}


ul.demo {
  list-style-type: none;
  margin: 0;
  padding: 0;
}    

.colored_back {
  background-color: purple;
}

</style>